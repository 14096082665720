import { gql } from '@apollo/client';
import {
  errorFragmentStr,
  formFragmentStr,
  formGroupFragmentStr,
  inputFragmentStr,
  multiSelectFragmentStr,
  optionValuesFragmentStr,
  textPairFragmentStr,
} from '../../query/fragmentStrings';

export const SIGN_IN_BLOCK_QUERY = gql`
  query SIGN_IN_BLOCK_QUERY {
    userState { isLoggedIn }
    enableElevatedAccountInMobileNav: featureFlag(key: "enable-elevated-account-in-mobile-nav")
    featureConfig {
      signInRestructureSwitch: json(key: "customer-sign-in-restructure-switches")
    }
    config {
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
    }
  }
`;

export const SIGN_IN_TOASTER_QUERY = gql`
  query SIGN_IN_TOASTER_QUERY {
    textFor {
      toasterTitle: pair(pairKey: "signInToasterTitle") { ...textPair }
      closeButtonLabel: pair(pairKey: "signInToasterCloseButtonText") { ...textPair }
    }
  }

  ${textPairFragmentStr}
`;

export const SIGN_IN_BUTTON_QUERY = gql`
  query SIGN_IN_BUTTON_QUERY {
    textFor {
      signInOrJoinText: pair(pairKey: "signInOrJoin") { ...textPair }
    }
  }

  ${textPairFragmentStr}
`;

export const LOGGED_IN_BUTTON_QUERY = gql`
  query LOGGED_IN_BUTTON_QUERY {
    textFor {
      tierImageAltText: pair(pairKey: "loyaltyTierLogoLabel") { ...textPair }
      welcomeMessageText: nonCachedPair(pairKey: "welcomeMessage") { ...textPair }
      availableRewardsCountText: nonCachedPair(pairKey: "availableRewardsCount") { ...textPair }
      rewardsLabelText: pair(pairKey: "navRewardsLabel") { ...textPair }
    }
    config {
      tierImageSrc: nonCachedString(name: "loyaltyTierImageUrl")
    }
  }

  ${textPairFragmentStr}
`;

export const LOGGED_IN_MENU_QUERY = gql`
  query LOGGED_IN_MENU_QUERY {
    textFor {
      loyaltyTierImageAltText: pair(pairKey: "loyaltyTierLogoLabel") {...textPair}
      yourAccountHeadingText: pair(pairKey: "yourAccount") {...textPair}
      signOutButtonText: pair(pairKey: "signOut") {...textPair}
      navLinks: listField(fieldKey: "signInNavMenuLinks") {...linkListValues}
      joinedDateText: nonCachedPair(pairKey: "userRegistrationDate") {...textPair}
      nextRewardText: nonCachedPair(pairKey: "untilNextRewards") {...textPair}
      repudiationWarning: nonCachedPair(pairKey: "crossBorderRepudiationWarning") {...textPair}
      welcomeMessageText: nonCachedPair(pairKey: "welcomeMessage") {...textPair}
    }
    config {
      loyaltyTierImageSrc: nonCachedString(name: "loyaltyTierImageUrl")
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
      isLoyaltyCrossBorderRepudiation: bool(name: "isLoyaltyCrossBorderRepudiation")
    }
    isProgressBarEnabled: featureFlag(
      key: "customer-toaster-progressbar-enabled",
      defaultValue: false
    )
    hasSignInRecsEnabled: featureFlag(
      key: "has-signin-toaster-recs-enabled",
      defaultValue: false
    )
    userInfo {
      id
      hashedEmail
    }
    userCountry @client
  }

  fragment linkListValues on FieldOptionList {
    label { ...textPair }
    options {
      url: optionValue
      text {...textPair}
    }
  }

  ${textPairFragmentStr}
`;

export const LOGGED_OUT_MENU_QUERY = gql`
  query LOGGED_OUT_MENU_QUERY {
    textFor {
      signInButtonText: pair(pairKey: "signIn") {...textPair}
      yourAccountHeadingText: pair(pairKey: "yourAccount") {...textPair}
      joinDescriptionText: pair(pairKey: "joinDescription") {...textPair}
      createAccountText: pair(pairKey: "createAccount") {...textPair}
      navLinks: listField(fieldKey: "signInNavMenuLinks") {...linkListValues}
    }
  }

  fragment linkListValues on FieldOptionList {
    label { ...textPair }
    options {
      url: optionValue
      text {...textPair}
      redirectRoute
    }
  }

  ${textPairFragmentStr}
`;

export const SIGNOUT_BUTTON_QUERY = gql`
  query SIGNOUT_BUTTON_QUERY {
    textFor {
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") {...textPair}
      success: pair(pairKey: "success") {...textPair}
    }
    config {
      homeRedirectUrl: string(name: "homeRedirectUrl")
    }
  }

  ${textPairFragmentStr}
`;

export const LOGOUT_MUTATION = gql`
  mutation LOGOUT_MUTATION {
    logout { success }
  }
`;

export const LOYALTY_COVERSION_PROMPT_QUERY = gql`
  query LOYALTY_COVERSION_PROMPT_QUERY {
    textFor {
      loyaltyConversionLegalText: pair(pairKey: "loyaltyConversionLegalText") {...textPair}
      loyaltyConversionSubmitText: pair(pairKey: "loyaltyConversionSubmitText") {...textPair}
      loyaltyConversionDenialText: pair(pairKey: "loyaltyConversionDenialText") {...textPair}
      processing: pair(pairKey: "processing") { ...textPair }
      success: pair(pairKey: "success") { ...textPair }
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
    }
  }

  ${textPairFragmentStr}
`;

export const LOGIN_MUTATION = gql`
  mutation LOGIN_MUTATION (
    $email : String!,
    $password : String!,
    $keepMeSignedIn : Boolean
    $isLoyaltyConversionTermsAccepted : Boolean
    $fastEnrollCampaignId: String,
  ) {
    login (
      email : $email,
      password: $password,
      keepMeSignedIn: $keepMeSignedIn
      isLoyaltyConversionTermsAccepted: $isLoyaltyConversionTermsAccepted
      fastEnrollCampaignId: $fastEnrollCampaignId,
    ) {
      success
      userId
      isPasswordUpdateRequired
      errors { ...error }
    }
  }

  ${errorFragmentStr}
`;

export const SIGN_IN_FORM_QUERY = gql`
  query SIGN_IN_FORM_QUERY {
    textFor {
      # Submit Button State Text
      signIn: pair(pairKey: "signIn") { ...textPair }
      processing: pair(pairKey: "processing") { ...textPair }
      success: pair(pairKey: "success") { ...textPair }
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
      or: pair(pairKey: "or") { ...textPair }
      # Text
      forgotPassword: pair(pairKey: "forgotPassword") { ...textPair }
      existingUser: pair(pairKey: "signInExistingUser") { ...textPair }
      createAccount: pair(pairKey: "createAccount") { ...textPair }
      loyaltyAboutLabel: pair(pairKey: "loyaltyAboutLabel") { ...textPair }
      errorSomethingWentWrong: pair(pairKey: "errorSomethingWentWrong") { ...textPair }
      showPasswordText: pair(pairKey: "showPassword") {...textPair}
      hidePasswordText: pair(pairKey: "hidePassword") {...textPair}
      # Sign In Form text
      signInForm: form(fieldKey: "signIn") { ...form }
    }
    config {
      # Loyalty About Link
      loyaltyAboutLink: string(name: "learnAboutLoyaltyLink")
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
    }
    userCountry @client
    signifydId @client
  }

  ${textPairFragmentStr}
  ${optionValuesFragmentStr}
  ${inputFragmentStr}
  ${multiSelectFragmentStr}
  ${formGroupFragmentStr}
  ${formFragmentStr}
`;

export const REMEMBERED_SIGN_IN_FORM_QUERY = gql`
  query REMEMBERED_SIGN_IN_FORM_QUERY {
    textFor {
      # Submit Button State Text
      signIn: pair(pairKey: "signIn") { ...textPair }
      processing: pair(pairKey: "processing") { ...textPair }
      success: pair(pairKey: "success") { ...textPair }
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
      # Text
      welcomeMessage: nonCachedPair(pairKey: "rememberedWelcomeMessage") { ...textPair }
      enterPassword: pair(pairKey: "rememberedEnterPassword") { ...textPair }
      forgotPassword: pair(pairKey: "forgotPassword") { ...textPair } # New TextPair, forgot password text (GLB_FORGOTPASSWORDQ)
      notYou: pair(pairKey: "signInNotYou") { ...textPair } # New TextPair, (ACT_NOTYOU)
      errorSomethingWentWrong: pair(pairKey: "errorSomethingWentWrong") { ...textPair }
      # Sign In Form text and legal
      rememberedSignInForm: form(fieldKey: "signIn") { ...form } # New Form, full form data construct
    }
  }

  ${textPairFragmentStr}
  ${optionValuesFragmentStr}
  ${inputFragmentStr}
  ${multiSelectFragmentStr}
  ${formGroupFragmentStr}
  ${formFragmentStr}
`;

export const JOIN_NOW_FORM_QUERY = gql`
  query JOIN_NOW_FORM_QUERY {
    textFor {
      # Submit Button State Text
      signIn: pair(pairKey: "signIn") { ...textPair }
      createAccount: pair(pairKey: "createAccount") { ...textPair }
      continue: pair(pairKey: "continue") { ...textPair }
      processing: pair(pairKey: "processing") { ...textPair }
      success: pair(pairKey: "success") { ...textPair }
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
      errorSomethingWentWrong: pair(pairKey: "errorSomethingWentWrong") { ...textPair }
      # Text
      almostDone: pair(pairKey: "joinStepTwoTitle") { ...textPair }
      joinNowPhoneNumberTooltipLabel: pair(pairKey: "joinNowPhoneNumberTooltipLabel") { ...textPair }
      joinNowTooltipWhyPhoneNumberText: pair(pairKey: "joinNowTooltipWhyPhoneNumber") { ...textPair }
      joinNowTooltipCantRememberText: pair(pairKey: "joinNowTooltipCantRemember") { ...textPair }
      joinNowTooltipMoreQuestionsText: pair(pairKey: "joinNowTooltipMoreQuestions") { ...textPair }
      loyaltyAboutLabel: pair(pairKey: "loyaltyAboutLabel") { ...textPair }
      createPasswordRulesText: pair(pairKey: "passwordCharacterLength") {...textPair}
      showPasswordText: pair(pairKey: "showPassword") {...textPair}
      hidePasswordText: pair(pairKey: "hidePassword") {...textPair}
      # Sign In Form text and legal
      joinForm: form(fieldKey: "join") { ...form }
      joinFormPrivacyTerms: legal(fieldKey: "joinFormPrivacyTerms") {
        text { ...textPair }
        error { ...textPair }
        check
      }
      joinLegal: legal(fieldKey: "joinFormLegalConsent") {
        text { ...textPair }
        error { ...textPair }
        check
      }
    }
    config {
      autoSelectedMarketingBrands: stringList(name: "signInAutoSelectedBrands")
      # Loyalty About Link
      loyaltyAboutLink: string(name: "learnAboutLoyaltyLink")
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
      toolTipCustomerServicePhoneNumber: string(name: "toolTipCustomerServicePhoneNumber")
    }
    isPasswordStrengthEnabled: featureFlag(
      key: "is-password-strength-meter-enabled"
    ),
    userType @client
    userCountry @client
    signifydId @client
  }

  ${textPairFragmentStr}
  ${optionValuesFragmentStr}
  ${inputFragmentStr}
  ${multiSelectFragmentStr}
  ${formGroupFragmentStr}
  ${formFragmentStr}
`;

export const USER_EXISTS_QUERY = gql`
  query USER_EXISTS_QUERY($email: String!) {
    isExistingUser: userExists(email : $email)
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION(
    $email: String!,
    $password: String!,
    $keepMeSignedIn: Boolean,
    $firstName: String!,
    $lastName: String!,
    $primaryPhone: String,
    $emailOptions: [BrandSelection],
    $ageAboveOrBelow: Boolean,
    $referralCode: String,
    $fastEnrollCampaignId: String
  ) {
    createUser(
      email: $email,
      password: $password,
      keepMeSignedIn: $keepMeSignedIn,
      firstName: $firstName,
      lastName: $lastName,
      primaryPhone: $primaryPhone,
      emailOptions: $emailOptions,
      ageAboveOrBelow: $ageAboveOrBelow,
      referralCode: $referralCode,
      fastEnrollCampaignId: $fastEnrollCampaignId,
    ) {
      success
      response
      errors { ...error }
    }
  }

  ${errorFragmentStr}
`;

export const AUTHENTICATION_FORM_QUERY = gql`
  query AUTHENTICATION_FORM_QUERY {
    userState { isRemembered }
    config {
      userAccountViewUrl: string(name: "userAccountViewUrl")
    }
  }
`;

export const AUTHENTICATION_REMEMBERED_QUERY = gql`
  query AUTHENTICATION_REMEMBERED_QUERY {
    userInfo { email }
  }
`;

export const PROGRESS_BAR_QUERY = gql`
  query PROGRESS_BAR_QUERY {
    textFor {
      pointsLabel: pair(pairKey: "pointsLabel") {...textPair}
      myPointsTitle: nonCachedPair(pairKey: "myPointsTitle") {...textPair}
    }
    config {
      maxProgressBarValue: int(name: "myPointsMaxProgressBarValue")
      currentProgressBarValue: int(name: "myPointsCurrentProgressBarValue")
    }
  }
  ${textPairFragmentStr}
`;

export const REWARDS_TEXT_QUERY = gql`
  query REWARDS_TEXT_QUERY {
    textFor {
      signInRewardsModalTitle: pair(pairKey: "signInRewardsModalTitle") {...textPair}
      signInRewardsModalDescription: pair(pairKey: "signInRewardsModalDescription") {...textPair}
      signInRewardsModalButton: pair(pairKey: "signInRewardsModalButton") {...textPair}
      redeemInBagText: pair(pairKey: "redeemInBag") {...textPair}
      exclusionsApplyLabelText: pair(pairKey: "exclusionsApplyLabel") {...textPair}
      seeDetailsLabel: pair(pairKey: "seeDetailsLabel") {...textPair}
      seeRewardsLabel: pair(pairKey: "seeRewardsLabel") {...textPair}
      rewardsAccordionTitleText: nonCachedPair(pairKey: "rewardsAccordionTitle") {...textPair}
      estimatedRewardPointsText: nonCachedPair(pairKey: "estimatedRewardPoints") {...textPair}
      nextRewardText: nonCachedPair(pairKey: "untilNextRewards") {...textPair}
      bdayMonthDoublePointsDesc: nonCachedPair(pairKey: "bdayMonthDoublePointsDesc") {...textPair}
    }
    config {
      seeDetailsLinkUrl: string(name: "seeDetailsLinkUrl")
      seeAllRewardsLinkUrl: string(name: "seeAllRewardsLinkUrl")
    }
    coupons {
      ... on Reward {
        id
        title {...textPair}
        description {...textPair}
        badge
        expiration {...textPair}
        exclusionLink
      }
      ... on Promotion {
        id
        title {...textPair}
        description {...textPair}
        badge
        expiration {...textPair}
        exclusionLink
      }
    }
    isProgressBarEnabled: featureFlag(
      key: "customer-toaster-progressbar-enabled",
      defaultValue: false
    )
    enableSignInRewardsModal: featureFlag(
      key: "enable-signin-rewards-notification-modal",
      defaultValue: false
    )
    enableDoubleBdayPointsText: featureFlag(
      key: "enable-double-bday-points-text",
      defaultValue: false
    )
  }

  ${textPairFragmentStr}
`;

export const MOBILE_SIGN_IN_QUERY = gql`
  query MOBILE_SIGN_IN_QUERY {
    userState { isLoggedIn }
    config {
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
    }
  }
`;

export const MOBILE_SIGN_IN_BUTTON_QUERY = gql`
  query MOBILE_SIGN_IN_BUTTON_QUERY {
    textFor {
      signInOrJoinText: pair(pairKey: "signInOrJoin") { ...textPair }
      viewAccountText: pair(pairKey: "viewAccount") { ...textPair }
    }
  }

  ${textPairFragmentStr}
`;
