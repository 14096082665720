import { gql, useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import ProductGridContext from '../../context/ProductGridContext';
import BreakpointContext from '../../context/breakpoint';
import DigitalDataContext from '../../context/digitalData';
import { IMAGE_SIZE_POLICY } from '../../tools/constants';
import getImageUrl from '../../tools/getImageUrl';
import { DD_DISABLE_SWATCH_HOVER } from '../DigitalDataProvider';
import { swatchType } from '../types';
import SwatchTile from './SwatchTile';
import SwatchTileGroup from './SwatchTileGroup';
import style from './productCard.module.scss';
import getSwatchLimit from './tools/getSwatchLimit';

const getProductDataQuery = gql`
  query Product($productId: String!) {
    product(productId: $productId) {
      id
      productPageUrl
    }
  }
`;

const ProductSwatches = ({
  handleSwatchHover = () => { },
  limitSwatches = false,
  productId = '',
  productName = '',
  productPageUrl = '',
  showPlaceholder = true,
  swatchList = [],
}) => {
  const [getProductData] = useLazyQuery(getProductDataQuery, {
    ssr: false,
    onCompleted: (data) => {
      window.location.assign(data.product.productPageUrl);
    },
  });

  const { imageHostName } = useContext(ProductGridContext);
  const { large: isDesktop } = useContext(BreakpointContext);
  const {
    [DD_DISABLE_SWATCH_HOVER]: digitalDataDisableSwatchHover,
  } = useContext(DigitalDataContext);

  const swatchLimit = getSwatchLimit(isDesktop);

  const handleSwatchClick = (e) => {
    const clickedSwatchProductId = e.currentTarget.value;
    /** If the currently selected swatch is the one clicked,
     * don't waste time fetching it's data that we already have */
    if (clickedSwatchProductId === productId) {
      window.location.assign(productPageUrl);
    } else {
      getProductData({ variables: { productId: clickedSwatchProductId } });
    }
  };

  const swatchTiles = swatchList.map((swatch) => (
    <Fragment key={swatch.id}>
      {
        imageHostName
        && (
          <SwatchTile
            handleSwatchHover={handleSwatchHover}
            id={`${productId}__${swatch.id}__swatch`}
            image={getImageUrl(imageHostName, swatch.swatchImage, IMAGE_SIZE_POLICY.XSMALL)}
            isChecked={swatch.product.id === productId}
            isLabelVisible={false}
            label={`${swatch.name} swatch`}
            name={productId}
            onClick={handleSwatchClick}
            showPlaceholder={showPlaceholder}
            swatch={swatch}
            type="button"
            value={swatch.product.id}
          />
        )
      }
    </Fragment>
  ));

  const getAdditionalSwatchCount = () => {
    const additionalSwatchText = `+${swatchList.length - swatchLimit}`;
    return isDesktop
      ? <div className={style.swatchLength}>{additionalSwatchText}</div>
      : <a className={style.swatchLength} href={productPageUrl}>{additionalSwatchText}</a>;
  };

  return (
    <div
      className={classNames(
        style.swatches,
        { [style.noHover]: digitalDataDisableSwatchHover },
      )}
      data-aui="product-card-swatches"
    >
      <SwatchTileGroup legend={`${productName} swatches`}>
        {swatchTiles.slice(0, limitSwatches
          ? Math.min(swatchLimit, swatchTiles.length)
          : swatchTiles.length)}
        {(limitSwatches && swatchList.length > swatchLimit) && getAdditionalSwatchCount()}
      </SwatchTileGroup>
    </div>
  );
};

ProductSwatches.propTypes = {
  handleSwatchHover: PropTypes.func,
  limitSwatches: PropTypes.bool,
  productId: PropTypes.string,
  productName: PropTypes.string,
  productPageUrl: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  swatchList: PropTypes.arrayOf(swatchType),
};

export default ProductSwatches;
