import {
  gql,
} from '@apollo/client';

// MUTATION: gql mutation to update charity data and fetch updated cart data
const CHARITY_UPDATE_MUTATION = gql`
mutation UpdateCharity(
  $charityInfo: CharityInput!
) {
  updateCharity: updateCharity(
    charityInfo: $charityInfo
  ) {
    cartInfo {
      messages {
        cardPosition
        catentryId
        categoryId
        message
        messageKey
        orderItemId
        params
        promotions {
          orderId
          promotionId
          promotionCode
          promotionType
          shortDesc
          longDesc
          isShippingPromo
          isRewardPromo
          isPromoBadging
          isImplicitPromo
          amount
          amountFmt
          orderAdjustmentId
          promoCompId
          promotionKey
        }
        repudiateType
        shortSku
        type
      }
        charityInfo {
          id
          brand
          option
          flag
        }
        availableShippingMethods {
          amount
          amountFmt
          description
          estimatedDeliveryDate
          estimatedDeliveryDateFmt
          externalId
          isEnabled
          shipModeId
          shipModeType
        }
        shipMethod {
          amount
          amountFmt
          description
          estimatedDeliveryDate
          estimatedDeliveryDateFmt
          externalId
          isSelected
          isEnabled
          shipModeId
          shipModeType
        }
        shippingAddress {
          address1
          address2
          addressId
          addressType
          city
          collectionPointID
          collectionPointProvider
          country
          displayType
          district
          email
          firstName
          isAPOFPO
          isBillingAllowed
          isDefaultBilling
          isDefaultShipping
          isShippingAllowed
          lastName
          legendAddress
          legendName
          name
          orgName
          phone
          phoneticName
          pickUpStoreId
          poBox
          postalCode
          prefecture
          province
          sameDayStoreId
          state
          subType
          title
          zone
        }
        orderId
        totals {
          charityRoundUp
          charityRoundUpFmt
          charityTotal
          charityTotalFmt
          charityTotalUSD
          currency
          estimatedTax
          estimatedTaxFmt
          estimatedTaxUSD
          giftBoxTotal
          giftBoxTotalFmt
          giftBoxTotalUSD
          grandTotal
          grandTotalFmt
          grandTotalUSD
          hasGiftReceipt
          productTotalAdjustment
          productTotalAdjustmentFmt
          productTotal
          productTotalFmt
          shippingCharge
          shippingChargeFmt
          shippingChargeUSD
          subTotal
          subTotalFmt
          subTotalUSD
          totalAdjustment
          totalAdjustmentFmt
          totalAdjustmentUSD
          totalMemberSavingsFmt
          vatIncluded
          vatRemoved
          vatRemovedFmt
        }
        userId
        payment {
          giftCardPayments {
            id
            maskedNumber
            amount
            amountFmt
            unusedAmount
            unusedAmountFmt
          }
        }
      }
      charity {
        name
        id
        brand
        sku
        option {
          id
          name
          checked
          charityAmount
        }
      }
      klarnaState {
        isEnabled
        orderInfo {
          locale
          merchant_reference1
          merchant_reference2
          order_amount
          order_lines {
            image_url
            product_url
            type
            reference
            quantity
            unit_price
            total_amount
            name
          }
          order_tax_amount
          purchase_country
          purchase_currency
        }
        sessionRequestBody {
          orderId
          purchaseCountry
          currency
          locale
          orderTotal
          tax
          shippingDetails {
            type
            name
            quantity
            unitPrice
            totalAmount
          }
          items {
            type
            shortSku
            orderItemId
            quantity
            price
            offerPrice
            contractPrice
            listPrice
            name
            productUrl
            imageUrl
            adjustments {
              shippingPromo
              type
              name
              quantity
              unitPrice
              totalAmount
            }
          }
          adjustments {
            shippingPromo
            type
            name
            quantity
            unitPrice
            totalAmount
          }
          attributes {
            options {
              color_details
              color_button
              color_button_text
              color_checkbox
              color_checkbox_checkmark
              color_header
              color_link
              color_text
              color_text_secondary
            }
          }
        }
      }
      shippingSpeed {
        amount
        amountDisplay
        addressType
        country
        description
        estimatedDeliveryDate
        estimatedDeliveryDateDisplay
        externalId
        isSelected
        isValid
        isEnabled
        shipModeId
        shipModeType
        state
      }
      statusCode
      success
      statusMessages {
        code
        key
        message
      }
      orderTotals {
        orderId
        subTotal
        totalItemsInBag
        cartTotalAmount
        cartPayableAmount
        subTotalFmt
        currency
        promoAmountFmt
        shippingHandlingChargeFmt
        totalGiftBoxes
        totalGiftBoxesAmount
        isGiftReceiptChecked
        displayFreeShippingText
        displayGiftReceiptText
        estimatedTaxAmountFmt
        charityRoundUpFmt
        includesConsumptionTax
        includesGst
        includesVat
        grandTotal
        grandTotalFmt
        charityTotalFmt
        subTotalUSD
        grandTotalUSD
        cartTotalAmountUSD
        totalAdjustmentUSD
        shippingChargeUSD
      }
      giftCards {
        isAllowedGiftCards
        appliedGiftCards {
          id
          maskedNumber
          amountFmt
        }
      }
      paymentConfig {
        creditCard {
          creditCardTypes {
            type
            maxlength
            code
            regex
          }
          cardNumberLabel { ...values }
          expiryLabel { ...values }
          securityCodeLabel { ...values }
          securityCodeImage
          securityCodeMessage { ...values }
          amountLabel { ...values }
          creditCardMissingErrorMessage { ...values }
          creditCardErrorMessage { ...values }
          expiryErrorMessage { ...values }
          cvvErrorMessage { ...values }
          unableToProcessCardErrorMessage { ...values }
        }
        googlePay {
          mid
          sid
          allowedMethods
          allowedCardNetworks
          amountStatus
          profile
          publicKeyApi
          useGooglePayButton
          merchantNo
          merchantName
          pxpScriptURL
        }
        applePay {
          mid
          sid
          publicKeyApi
          pxpScriptURL
          tokenVaultURL
          applePayRequest {
            countryCode
            currencyCode
            merchantCapabilities
            supportedNetworks
            total {
              label
              amount
              type
            }
            shippingMethods {
              amount
              detail
              identifier
              label
            }
            lineItems {
              label
              amount
            }
          }
        }
        savedPayments {
          iconCode
          maskedCardNumber
          cardExpiryMonth
          cardExpiryYear
          cardType
          requiresCvv
          savedPaymentId
          default
        }
      }
      paymentOptions {
        id
        name
        type
        code
        description {
          key
          value
        }
        message {
          key
          value
        }
        images {
          altText
          imageSrc
        }
        icon
        disabled
        savable
        default
      }
    }
}
fragment values on TextPair {
  key
  value
}
`;

export default CHARITY_UPDATE_MUTATION;
