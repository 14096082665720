import React from 'react';
import PropTypes from 'prop-types';
import { LinkListArrow } from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { textProp } from '../../tools/customProps';

const LinkProp = PropTypes.shape({
  text: textProp,
  url: PropTypes.string,
});

const propTypes = {
  links: PropTypes.arrayOf(LinkProp).isRequired,
  onMyAbercrombie: PropTypes.func,
  onOpenAuthModal: PropTypes.func,
};

const defaultProps = {
  onMyAbercrombie: () => {},
  onOpenAuthModal: () => {},
};

export default function NavigationLinks({ links, onOpenAuthModal, onMyAbercrombie }) {
  const linkProps = links.map(({ text, url, redirectRoute = undefined }) => {
    const { key } = text;
    const tmnt = <TmntText tmnt={text} />;

    if (url.includes('/UserAccountView')) {
      return ({
        children: tmnt,
        key,
        onClick: () => {
          window.location.assign(url);
        },
      });
    }

    if (url === 'authentication-modal:open') {
      return ({
        children: tmnt,
        key,
        onClick: () => onOpenAuthModal({ isSignInTab: true, redirect: redirectRoute }),
      });
    }

    if (url.includes('/RewardsCenterDisplayView')) {
      return ({
        children: tmnt,
        key,
        onClick: () => onMyAbercrombie(url),
      });
    }

    return ({ children: tmnt, key, href: url });
  });

  return (
    <div className="navigation-links">
      <LinkListArrow links={linkProps} />
    </div>
  );
}

NavigationLinks.propTypes = propTypes;
NavigationLinks.defaultProps = defaultProps;
