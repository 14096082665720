import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button, Link as DSLink } from 'anf-core-react';
import { FormWrapper, FormGroup, FormCell } from '../FormStructure';
import NavigationLinks from './NavigationLinks';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { useTealiumTrigger } from '../../hooks';
import {
  useAuthenticationFormDispatch,
  AUTH_FORMS,
} from '../../context/AuthenticationFormContext/AuthenticationFormContext';
import { AuthenticationModalContext, SignInToasterContext } from '../../context/ModalContext';
import { LOGGED_OUT_MENU_QUERY } from './operations';

const propTypes = { isMobileToaster: PropTypes.bool };
const defaultProps = { isMobileToaster: false };

let textCache = {};

export default function LoggedOutMenu({ isMobileToaster }) {
  const { closeModal: closeToaster } = useContext(SignInToasterContext);
  const { openModal } = useContext(AuthenticationModalContext);
  const dispatch = useAuthenticationFormDispatch();
  const triggerTealiumSignIn = useTealiumTrigger('sign_up', 'SIGN_IN');

  const {
    loading: isLoading, error: queryError, data: queryData = {},
  } = useQuery(LOGGED_OUT_MENU_QUERY);

  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const { navLinks } = textFor;

  textCache = textFor;

  const dispatchLoginEvent = (settings = {}) => {
    const { isSignInTab = false, redirect } = settings; // Passed in from NavigationLinks

    if (!isMobileToaster) closeToaster();

    if (isSignInTab) dispatch({ type: 'showForm', form: AUTH_FORMS.SIGN_IN, redirect });
    else dispatch({ type: 'showForm', form: AUTH_FORMS.JOIN });

    openModal();
    triggerTealiumSignIn();
  };

  return (
    <div className="logged-out-menu" data-testid={!isLoading ? 'logged-out-menu' : 'logged-out-menu--loading'}>
      <FormWrapper>
        <FormGroup>
          <FormCell isLoading={isLoading}>
            <h2 className="h1">
              <TmntText tmnt={textCache?.yourAccountHeadingText} />
            </h2>
          </FormCell>
          <FormCell isLoading={isLoading}>
            <Button
              variant="secondary"
              onClick={() => dispatchLoginEvent({ isSignInTab: true })}
              isFullWidth
            >
              <TmntText tmnt={textCache?.signInButtonText} />
            </Button>
          </FormCell>
          <FormCell isLoading={isLoading}>
            <div className="join-link-container">
              <TmntText tmnt={textCache?.joinDescriptionText} />
              {' '}
              <DSLink onClick={() => dispatchLoginEvent()}>
                <b>
                  <TmntText tmnt={textCache?.createAccountText} />
                </b>
              </DSLink>
            </div>
          </FormCell>
          <FormCell isLoading={isLoading}>
            {
              !navLinks
                ? null
                : <NavigationLinks links={navLinks.options} onOpenAuthModal={dispatchLoginEvent} />
            }
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </div>
  );
}

LoggedOutMenu.propTypes = propTypes;
LoggedOutMenu.defaultProps = defaultProps;
