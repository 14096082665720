import DigitalDataProvider from './DigitalDataProvider';
import DigitalDataTestProvider from './DigitalDataTestProvider';

// TEST FLAGS
const DD_DISABLE_SWATCH_HOVER = 'test.disableSwatchHover';
const DD_HYPERLINK_DESC = 'test.mfeHyperlinkDescriptor';
const DD_MODEL_IMAGERY_TEST = 'test.modelImageryTest';
const DD_PAGINATION_TEST = 'test.paginationVsLoadMoreTest';
const DD_SHOW_ANF_NAVIGATION_L3S_TEST = 'test.showAnfNavigationL3s';
const DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST = 'test.searchFlyoutAlgonomySuggestions';

const DD_DISCOVER_BY_FACET_TEST = 'test.discoverByFacet';

// FEATURE FLAGS
const DD_DISCOVER_BY_FACET_FLAG = 'flag.discover-by-facet';
const DD_EDITABLE_SEARCH_FLAG = 'flag.editable-search';
const DD_SEARCH_HELP_TOPICS = 'flag.search-flyout-help-topics';
const DD_TIK_TOK_FOOTER_ICON_FLAG = 'flag.tik-tok-footer-icon';
const DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS = 'flag.search-flyout-algonomy-suggestions';
const DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS = 'flag.no-results-department-suggestions';
const DD_USER_PREFERENCE_BOB_TOASTER_FLAG = 'flag.user-preference-bob-toaster';
const DD_TAP_TO_DISCOVER = 'flag.tap-to-discover-attributes';
const DD_FRANCHISE_PORTAL = 'flag.franchise-portal';
const DD_SEARCH_FLYOUT_RECS_MIGRATION = 'flag.search-flyout-recs-migration';

export {
  DigitalDataTestProvider,
  DD_DISABLE_SWATCH_HOVER,
  DD_DISCOVER_BY_FACET_FLAG,
  DD_DISCOVER_BY_FACET_TEST,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
  DD_EDITABLE_SEARCH_FLAG,
  DD_SEARCH_HELP_TOPICS,
  DD_TIK_TOK_FOOTER_ICON_FLAG,
  DD_SHOW_ANF_NAVIGATION_L3S_TEST,
  DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS,
  DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
  DD_USER_PREFERENCE_BOB_TOASTER_FLAG,
  DD_TAP_TO_DISCOVER,
  DD_FRANCHISE_PORTAL,
  DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST,
  DD_SEARCH_FLYOUT_RECS_MIGRATION,
};

export default DigitalDataProvider;
