import React from 'react';
import SwitchTestProvider from '../../SwitchTestProvider/SwitchTestProvider';
import { MINI_BAG_SWITCHES } from '../../../tools/constants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import MiniBagWithIconV2 from '../MiniBagWithIconV2/MiniBagWithIconV2';

export default function MiniBagWrapperMobile() {
  const { isFeatureFlagOn: enableMinibagV2, loading } = useFeatureFlag('chk-use-mfe-minibag-with-icon-v2', false, { fetchPolicy: 'cache-first' });

  if (loading) {
    return null;
  }

  if (enableMinibagV2) {
    return (
      <SwitchTestProvider keys={MINI_BAG_SWITCHES}>
        <MiniBagWithIconV2 isMobileMiniBag />
      </SwitchTestProvider>
    );
  }

  // return null when the flag is not enabled, mobile mini bag
  // will be rendered using portal through MiniBagWrapperMobile.
  return null;
}
