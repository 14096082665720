import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import ORDER_SUBMIT_MUTATION from '../../../gql/orderSubmit.gql';
import prepareFormData from '../../CheckoutPage/SubmitButton/prepareFormData';

export default function ApplePayExpress({ page, orderConfirmationURL }) {
  const triggerApplePayError = useCallback((errorMessage) => {
    const newEvent = new Event('mfe:applepay:error');
    newEvent.detail = errorMessage;
    window.dispatchEvent(newEvent);
  }, []);

  const redirectToOrderConfirmation = (currentPage, redirectURL, data = {}) => {
    // trigger an event for CRS to listen to and submit the form
    // this evenet will pass the data that was returned from CART
    // currently required for proper redirect to order confirmation page
    if (currentPage === 'bag') {
      window.location.href = redirectURL;
    } else {
      const event = new CustomEvent('mfe:submit:successful', {
        detail: data,
      });
      window.dispatchEvent(event);
    }
  };

  const [orderSubmit] = useMutation(ORDER_SUBMIT_MUTATION, {
    onCompleted: (data) => {
      if (data?.orderSubmit?.success) {
        redirectToOrderConfirmation(page, orderConfirmationURL, data?.orderSubmit);
      } else {
        triggerApplePayError(
          data?.orderSubmit?.statusMessages?.[0]?.message ?? 'Something went wrong. Please try again.',
        );
      }
    },
    onError: (error) => {
      triggerApplePayError(error?.message);
    },
  });

  useEffect(() => {
    function handleApplePaySubmitEvent(event) {
      const { success, errorMessage, formData } = event.detail;
      const submitData = prepareFormData(formData);
      const currentPaymentInfo = {
        paymentType: 'applepay',
      };

      if (success) {
        orderSubmit({
          variables: {
            orderSubmitInput: submitData,
            paymentInfo: currentPaymentInfo,
          },
        });
      } else {
        // trigger an event for Page Header to display the error message
        triggerApplePayError(errorMessage);
      }
    }
    window.addEventListener('mfe:checkout:submit', handleApplePaySubmitEvent);
    return () => {
      window.removeEventListener('mfe:checkout:submit', handleApplePaySubmitEvent);
    };
  }, [orderSubmit, triggerApplePayError]);

  // handle custom submit hook events for state changes and error handling
  useEffect(() => {
    function handleApplePaySubmit() {
      // clear error message on submit
      triggerApplePayError('');
    }

    // set error message on submit error
    function handleApplePaySubmitError(event) {
      const { message } = event.detail;
      triggerApplePayError(message);
    }

    // redirect to order confirmation page
    function handleApplePaySubmitSuccess(event) {
      const data = event?.detail;
      redirectToOrderConfirmation(page, orderConfirmationURL, data);
    }

    window.addEventListener('mfe:order:submit', handleApplePaySubmit);
    window.addEventListener('mfe:orderSubmit:error', handleApplePaySubmitError);
    window.addEventListener('mfe:orderSubmit:success', handleApplePaySubmitSuccess);
    return () => {
      window.removeEventListener('mfe:orderSubmit:error', handleApplePaySubmitError);
      window.removeEventListener('mfe:order:submit', handleApplePaySubmit);
      window.removeEventListener('mfe:orderSubmit:success', handleApplePaySubmitSuccess);
    };
  }, [triggerApplePayError, page, orderConfirmationURL]);

  return null;
}

ApplePayExpress.propTypes = {
  page: PropTypes.string.isRequired,
  orderConfirmationURL: PropTypes.string.isRequired,
};
