import { useEffect } from 'react';

export const LEGAL_POLICY_MODAL_TRIGGER = 'js-legal-policy-modal';

/**
 * Custom hook to handle click events on elements with the LEGAL_POLICY_MODAL_TRIGGER class.
 * @param {object} containerRef - React ref object for the container element.
 * @param {function} callback - Function to call when a valid element is clicked.
 */

const useLegalLinksEvent = (containerRef, callback) => {
  useEffect(() => {
    if (!containerRef) return null;

    const handleClick = (event) => {
      const target = event.target.closest(`.${LEGAL_POLICY_MODAL_TRIGGER}`);

      // If a valid target is found within the container, call the callback with the data key
      if (target && containerRef.current.contains(target)) {
        const key = target.getAttribute('data-textkey');
        if (key) {
          callback(key);
        }
      }
    };
    const container = containerRef.current;

    if (container) {
      container.addEventListener('click', handleClick);
    }

    return () => {
      if (container) {
        container.removeEventListener('click', handleClick);
      }
    };
  }, [containerRef, callback]);
};

export default useLegalLinksEvent;
