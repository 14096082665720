import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { LegalText } from 'anf-core-react';
import { TmntHtml } from '../Text';
import { textProp } from '../../../tools/customProps';

const propTypes = { text: { tmnt: textProp.isRequired, tag: PropTypes.oneOf(['p', 'span']) } };
const defaultProps = { text: { tmnt: undefined, tag: 'span' } };

const LegalModalButton = forwardRef(({ text }, ref) => (
  <span ref={ref} data-testid="legal-modal-button">
    <LegalText>
      <TmntHtml tmnt={text} />
    </LegalText>
  </span>
));

export default LegalModalButton;
LegalModalButton.propTypes = propTypes;
LegalModalButton.defaultProps = defaultProps;
