import handleShippingSpeedUpdate from './handleShippingSpeedUpdate';
import { getStateForCountry } from './address';
import prepareFormData from '../components/CheckoutPage/SubmitButton/prepareFormData';
import delay from './delay';

const shippingContactSelected = async (
  event,
  paymentRequest,
  updateShippingSpeedMutation,
  countriesConfig,
) => {
  const { shippingContact } = event;
  const shippingAddress = {
    firstName: '',
    lastName: '',
    city: shippingContact.locality,
    state: getStateForCountry(
      shippingContact.countryCode,
      shippingContact.administrativeArea || shippingContact.countryCode,
      countriesConfig,
    ),
    country: shippingContact.countryCode,
    postalCode: shippingContact.postalCode,
  };
  const preparedShippingMethod = {
    shippingAddress,
  };
  const result = await handleShippingSpeedUpdate(
    preparedShippingMethod,
    updateShippingSpeedMutation,
  );

  const { paymentConfig } = result?.updateShippingSpeed ?? {};
  const { applePayRequest } = paymentConfig?.applePay ?? {};

  return {
    newTotal: applePayRequest?.total ?? paymentRequest.total,
    newLineItems: applePayRequest?.lineItems ?? paymentRequest.lineItems,
    newShippingMethods: applePayRequest?.shippingMethods ?? paymentRequest.shippingMethods,
  };
};

const shippingMethodSelected = async (event, paymentRequest, updateShippingSpeedMutation) => {
  const { shippingMethod } = event;
  const preparedShippingMethod = {
    shipModeId: shippingMethod.identifier,
  };
  const result = await handleShippingSpeedUpdate(
    preparedShippingMethod,
    updateShippingSpeedMutation,
  );
  const { paymentConfig } = result?.updateShippingSpeed ?? {};
  const { applePayRequest } = paymentConfig?.applePay ?? {};

  return {
    newTotal: applePayRequest?.total ?? paymentRequest.total,
    newLineItems: applePayRequest?.lineItems ?? paymentRequest.lineItems,
  };
};

const getAddressFromContact = (shippingContact, countriesConfig) => ({
  firstName: shippingContact.givenName,
  lastName: shippingContact.familyName,
  address1: shippingContact.addressLines?.[0] || '',
  address2: shippingContact.addressLines?.[1] || '',
  city: shippingContact.locality,
  state: getStateForCountry(
    shippingContact.countryCode,
    shippingContact.administrativeArea || shippingContact.countryCode,
    countriesConfig,
  ),
  country: shippingContact.countryCode,
  postalCode: shippingContact.postalCode,
  phone: shippingContact.phoneNumber,
  email: shippingContact.emailAddress,
});

const getOrderSubmitInput = (
  paymentResult,
  applePayRequest,
  applePaySettings,
) => {
  const { currentFormData, countriesConfig, pageState } = applePaySettings;
  const orderSubmitInput = currentFormData ?? {};
  const {
    transactionAccountId,
    clientSystemTransactionId,
    cryptogram,
    eciIndicator,
    shippingContact,
    billingContact,
  } = paymentResult;

  orderSubmitInput.payment = {
    applePayPayment: {
      appleTransactionId: transactionAccountId ?? clientSystemTransactionId,
      clientTransactionId: clientSystemTransactionId,
      cryptogram: cryptogram ?? '',
      eciIndicator: eciIndicator ?? '',
    },
    paymentCode: 'applePayPayment',
  };

  orderSubmitInput.shippingAddress = shippingContact
    ? getAddressFromContact(shippingContact, countriesConfig) : null;
  orderSubmitInput.billingAddress = billingContact
    ? getAddressFromContact(billingContact, countriesConfig) : null;

  orderSubmitInput.shipModeId = applePayRequest?.shippingMethods?.[0]?.identifier
    || currentFormData.shipModeId;
  orderSubmitInput.billingAddressSameAsShipping = !orderSubmitInput.billingAddress;

  // remove some unnecessary fields
  delete orderSubmitInput.orderSubmitLegalTerms;
  delete orderSubmitInput.orderSubmitPaymentMethod;

  return prepareFormData(orderSubmitInput, pageState);
};

const submitApplePayOrder = async (event, paymentRequest, applePaySettings) => {
  const { orderSubmit } = applePaySettings;
  const paymentResult = event.detail;
  const orderSubmitInput = getOrderSubmitInput(
    paymentResult,
    paymentRequest,
    applePaySettings,
  );

  try {
    // order submit mutation
    await orderSubmit({
      orderSubmitInput,
      paymentInfo: {
        paymentType: 'applepay',
      },
    });

    // success flow will let PXP know that the payment was successful
    // othewise an error will be caught in the catch block
    return {
      status: 0,
      message: 'success',
    };
  } catch (error) {
    // pxp will display Payment Not Complete in this case
    return {
      status: 1,
      message: error?.message,
    };
  }
};

// this function will be used to start the apple pay session based on a switch
// when PXP is ON
const initApplePayPXPSession = (applePayRequest, applePaySettings) => {
  const paymentRequest = {
    ...applePayRequest,
  };

  if (applePaySettings.isExpressFlow) {
    // this will display additional fields on the apple pay sheet
    paymentRequest.requiredShippingContactFields = ['email', 'name', 'phone', 'postalAddress'];
  } else {
    // no need to display shipping methods in Checkout flow
    delete paymentRequest.shippingMethods;
  }

  const callBackFunctions = {
    handleShippingContactSelected: async (event) => {
      // adding a delay to avoid optimistic locking
      await delay(1000);
      return shippingContactSelected(
        event,
        paymentRequest,
        applePaySettings.updateShippingSpeedMutation,
        applePaySettings.countriesConfig,
      );
    },
    handleShippingMethodSelected: async (event) => {
      // adding a delay to avoid optimistic locking
      await delay(1000);
      return shippingMethodSelected(
        event,
        paymentRequest,
        applePaySettings.updateShippingSpeedMutation,
      );
    },
    handlePaymentAuthorized: async (event) => submitApplePayOrder(
      event,
      paymentRequest,
      applePaySettings,
    ),
  };

  // adding this rule since this function will be a global function
  // loaded from applepay.js hosted by pxp
  try {
    // eslint-disable-next-line no-undef
    runApplePay(
      applePaySettings?.mid, // merchant id provided by PXP
      applePaySettings?.sid, // store id
      applePaySettings.clientSystemTransactionId, // generated transaction id
      applePaySettings?.publicKeyApi, // public key
      applePaySettings?.domainName, // domain name
      paymentRequest, // payment request
      applePaySettings?.useDigitalWalletButton, // we are using our own button
      applePaySettings?.buttonOptions,
      callBackFunctions,
      applePaySettings?.tokenVaultURL,
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error loading Apple Pay', err);
  }
};

// this function will be used to start the apple pay session based on a switch
// when PXP is OFF
const initApplePaySession = (applePayRequest, applePaySettings) => ({
  applePayRequest,
  applePaySettings,
});

// this function will be used to restart the apple pay session based on a switch
// either PXP flow or existing flow
const routeApplePaySession = (applePayRequest, applePaySettings, hasApplePayPXP) => {
  if (hasApplePayPXP) {
    return initApplePayPXPSession(applePayRequest, applePaySettings);
  }

  return initApplePaySession(applePayRequest, applePaySettings);
};

export {
  shippingContactSelected,
  shippingMethodSelected,
  getAddressFromContact,
  initApplePayPXPSession,
  initApplePaySession,
  routeApplePaySession,
  submitApplePayOrder,
  getOrderSubmitInput,
};
