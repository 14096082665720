import React, { useState, useContext } from 'react';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import RecognizeCustomerForm from '../recognize-customer-form/recognize-customer-form';

export default function RecognizeCustomer() {
  const [showRecognizeCustomer, setShowRecognizeCustomer] = useState(true);
  const guestCustomerMailId = sessionStorage?.getItem('guestCustomerMailId');

  const unmountRecognizeCustomer = () => {
    setShowRecognizeCustomer(false);
  };

  const {
    checkoutPageState,
  } = useContext(CheckoutPageContext);

  return (
    <section className="recognize-customer-section">
      {
          showRecognizeCustomer && !guestCustomerMailId && checkoutPageState?.textFor
            ? (
              <RecognizeCustomerForm
                unmount={unmountRecognizeCustomer}
                tmntData={checkoutPageState?.textFor}
              />
            )
            : null
        }
    </section>
  );
}
