import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import RecognizeCustomer from '../recognize-customer/recognize-customer';

export default function RecognizeCustomerPortal({ id }) {
  if (typeof document !== 'undefined' && !!document.getElementById(id)) {
    return ReactDOM.createPortal(
      <RecognizeCustomer />,
      document.getElementById(id),
    );
  }
  return null;
}

RecognizeCustomerPortal.defaultProps = {
  id: '',
};

RecognizeCustomerPortal.propTypes = {
  id: PropTypes.string,
};
