import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, ErrorMessage, Icon, InputField, PasswordField,
} from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';

/**
 * This component is displayed to users who have completed a purchase but are not
 * logged in. It utilizes the user's email from the order and prompts them to
 * create a password and accept terms and conditions.
 */
export default function CreateAccount({
  email,
  displayFormError,
  isPasswordInvalid,
  isTermsInvalid,
  onFormSubmit,
  isDisabledSubmit,
  isProcessingForm,
  buttonSubmitLabel,
  phoneNumber,
  firstName,
  lastName,
  showCheckbox,
}) {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { textFor, orderTotals } = orderConfirmationData;
  const [passwordValue, setPasswordValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const {
    password,
    passwordRequirements,
    passwordShow,
    passwordHide,
    termsAndConditions,
    emailLabel,
    formError,
  } = textFor;

  const { orderId } = orderTotals;

  const handleTermsChecked = () => setTermsChecked((prev) => !prev);
  const handlePasswordInput = (evt) => setPasswordValue(evt.target.value);
  const handleShowPassword = () => setShowPassword((prev) => !prev);
  const handleShowPasswordHint = () => setShowHint(true);

  const handleFormSubmit = (evt) => onFormSubmit(
    evt,
    passwordValue,
    termsChecked,
    firstName,
    lastName,
    phoneNumber,
  );

  return (
    <div className="ocn-create-account" data-test-id="create-account">
      <form
        id="create-account"
        onSubmit={handleFormSubmit}
        name="create-account-form"
        className="ocn-create-account-form"
      >
        <div className="ocn-create-account-form__email">
          <label htmlFor="user-email">
            <Tmnt isHtml tmnt={emailLabel.label} />
            :
            {email}
          </label>
          <input type="email" id="email" name="user-email" value={email} hidden disabled />
        </div>
        <input name="orderId" type="text" value={orderId} disabled hidden />
        <input name="emailEventProcessSource" value="Receipt" disabled hidden />
        <input
          name="phoneNumber"
          id="primaryPhone"
          type="text"
          value={phoneNumber}
          disabled
          hidden
        />
        <input name="firstName" id="firstName" type="text" value={firstName} disabled hidden />
        <input name="lastName" id="lastName" type="text" value={lastName} disabled hidden />
        <PasswordField>
          <InputField
            isInvalid={isPasswordInvalid}
            autoComplete="new-password"
            id="createAccountPassword"
            label={password.label.value}
            name="password"
            onChange={handlePasswordInput}
            type={showPassword ? 'text' : 'password'}
            value={passwordValue}
            onFocus={handleShowPasswordHint}
            isDisabled={isProcessingForm}
            describedBy="passwordRequirements"
          >
            <Button onClick={handleShowPassword}>
              <Icon
                icon={showPassword ? 'eye-filled' : 'eye'}
                labelText={showPassword ? passwordShow?.value : passwordHide?.value}
              />
            </Button>
          </InputField>
          {isPasswordInvalid && (
            <ErrorMessage id="createAccountPassword-error-message">
              <Tmnt attributes={{ 'data-testid': 'create-account-password-error-message' }} tmnt={password.error} />
            </ErrorMessage>
          )}
        </PasswordField>
        {showHint && (
          <p id="passwordRequirements" className="ocn-create-account-form__password-hint">
            <Tmnt tmnt={passwordRequirements} />
          </p>
        )}
        {
          showCheckbox ? (
            <Checkbox
              id="legal-terms"
              name="legalTerms"
              isChecked={termsChecked}
              isInvalid={isTermsInvalid}
              onChange={handleTermsChecked}
              value="consent"
              isDisabled={isProcessingForm}
              description={<Tmnt isHtml tmnt={termsAndConditions.label} />}
            >
              {isTermsInvalid && <ErrorMessage><Tmnt attributes={{ 'data-testid': 'create-account-terms-error-message' }} tmnt={termsAndConditions.error} /></ErrorMessage>}
            </Checkbox>
          ) : (
            <Tmnt isHtml tmnt={termsAndConditions.label} />
          )
        }
        <div id="legal-terms-error-message" hidden={!displayFormError}>
          <ErrorMessage id="recognizeCustomerForm__errorMessage"><Tmnt attributes={{ 'data-testid': 'create-account-form-error-message' }} tmnt={formError} /></ErrorMessage>
        </div>
        <Button
          isDisabled={isDisabledSubmit || isProcessingForm}
          isProcessing={isProcessingForm}
          variant="secondary"
          type="submit"
          isFullWidth
          classList="ds-override create-account-button"
        >
          <span><Tmnt tmnt={buttonSubmitLabel} /></span>
        </Button>
      </form>
    </div>
  );
}

CreateAccount.propTypes = {
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func,
  buttonSubmitLabel: PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
    .isRequired,
  isDisabledSubmit: PropTypes.bool,
  isProcessingForm: PropTypes.bool,
  displayFormError: PropTypes.bool,
  isPasswordInvalid: PropTypes.bool,
  isTermsInvalid: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

CreateAccount.defaultProps = {
  onFormSubmit: () => {},
  isDisabledSubmit: false,
  isProcessingForm: false,
  displayFormError: false,
  isPasswordInvalid: false,
  isTermsInvalid: false,
  showCheckbox: true,
};
