import React from 'react';
import { useQuery } from '@apollo/client';
import { UPPER_FUNNEL_LOGIN_CLIENT_QUERY } from './operations';
import UpperFunnelLogin from './UpperFunnelLogin';
import AuthenticationModal from '../SignIn/AuthenticationModal';

export default function UpperFunnelLoginClient() {
  const { data, error, loading } = useQuery(
    UPPER_FUNNEL_LOGIN_CLIENT_QUERY,
    {
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
      ssr: false,
    },
  );

  if (loading) return null;
  if (error) return null;

  const { config, featureConfig, userState } = data;
  const { isLoyaltyEnabled } = config;
  const { signInRestructureSwitch } = featureConfig;
  const { isLoggedIn } = userState;

  if (isLoggedIn) return null;

  try {
    const { upperFunnelLogin } = JSON.parse(signInRestructureSwitch);

    // Disabled if the switch is OFF
    if (!upperFunnelLogin) return null;
  } catch {
    // If parse fails, return null and fail gracefully
    return null;
  }

  return (
    <>
      <AuthenticationModal isLoyaltyEnabled={isLoyaltyEnabled} />
      <UpperFunnelLogin />
    </>
  );
}
