import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  ErrorMessage, Button, Checkbox, Icon, InputField, PasswordField, FieldGroup,
} from 'anf-core-react';
import isValidEmail from '../../../tools/isValidEmail';

import Tmnt from '../../Tmnt/Tmnt';
import useLog from '../../useLog/useLog';
import { userLoginApi } from '../../../gql/recognizeCustomerForm.gql';

export default function RecognizeCustomerForm({
  unmount,
  tmntData,
}) {
  const [emailValue, setEmailValue] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [formErrorText, setFormErrorText] = useState('');
  const [displayFormError, setDisplayFormError] = useState(false);
  const logger = useLog('checkout.recognizeCustomerForm');
  const [keepMeSignedIn, setKeepMeSigned] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  // Button Hooks
  const [submitButtonLabel, setSubmitButtonLabel] = useState('');
  const [submitButtonDisabledStyle, setSubmitButtonDisabledStyle] = useState(false);
  const [continueAsGuestDisabled, setContinueAsGuestDisabled] = useState(false);
  const [submitButtonLeftIcon, setSubmitButtonLeftIcon] = useState(undefined);

  const updateSubmitButtonState = (labelReference, disabledStyle = false, leftIcon = undefined) => {
    setSubmitButtonLabel(labelReference?.value);
    setSubmitButtonDisabledStyle(disabledStyle);
    setSubmitButtonLeftIcon(leftIcon);
  };

  const [userLoginMutation] = useMutation(userLoginApi, {
    onCompleted: ({ login }) => {
      if (login) {
        logger.debug('userLoginMutation RESULT', login);
        // user successfully logged in,
        // also dispatch an event for subscribers
        const event = new Event('RecognizeCustomer:UserLoggedIn');
        window.dispatchEvent(event);
        updateSubmitButtonState(tmntData?.buttonSuccess, true, 'check');
      } else {
        setPasswordInvalid(true);
        setDisplayFormError(true);
        setFormErrorText(tmntData?.formError.value);
        // set button to its default state again
        updateSubmitButtonState('submit');
      }
    },
  });

  const {
    checkoutButtonText,
    email,
    passwordLabel,
    buttonProcessing,
    formError,
    signInButton,
    keepMeSignedInCheckBox,
    keepMeSignedInUncheckMsg,
    orText,
    forgotPassword,
    dontHaveAnAccount,
    join,
    continueAsGuestButton,
    signInOrContinueAsGuestText,
    passwordShow,
    passwordHide,
    emailInvalidFormatText,
    emailInvalidText,
    passwordInvalidText,
  } = tmntData;
  const validateEmail = () => {
    const isValid = isValidEmail(emailValue);
    setEmailInvalid(!isValid);
    return isValid;
  };

  const loginUser = () => {
    userLoginMutation({
      variables: {
        email: emailValue,
        password: passwordValue,
      },
    });
  };

  const formSubmission = (e) => {
    e.preventDefault();
    if (submitButtonDisabledStyle) return;
    setPasswordInvalid(false);
    setDisplayFormError(false);
    setFormErrorText(formError?.value);

    const emailIsValid = validateEmail();
    if (!passwordValue) {
      setPasswordInvalid(true);
    }

    if (!emailIsValid || !passwordValue) {
      setDisplayFormError(true);
      updateSubmitButtonState(signInButton);
      return;
    }

    updateSubmitButtonState(buttonProcessing, true, 'spinner');
    loginUser();
  };

  const handleJoinMyAbercrombieClick = () => {
    // Dispatch the custom event to open the modal
    document.dispatchEvent(new CustomEvent('authentication-modal:open', {
      detail: { isSignIn: false },
    }));
  };

  const continueAsGuest = (e) => {
    e.preventDefault();

    if (continueAsGuestDisabled) return;
    setContinueAsGuestDisabled(true);
    setPasswordInvalid(false);
    setDisplayFormError(false);
    setFormErrorText(formError?.value);

    sessionStorage.setItem('guestCustomerMailId', emailValue || ' ');

    const event = new Event('RecognizeCustomer:ContinueAsGuest');
    window.dispatchEvent(event);
    unmount();
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <div>
      <h1 className="checkout-heading" data-testid="checkout-heading">
        <Tmnt tmnt={checkoutButtonText} isHtml />
      </h1>
      <div className="inner-wrapper">
        <div className="header">
          <Tmnt tmnt={signInOrContinueAsGuestText} isHtml />
        </div>
        <form onSubmit={formSubmission} name="recognize-customer-form">
          <FieldGroup
            legend="email address, password"
          >
            <InputField
              id="recognizeCustomerForm_email"
              type="email"
              label={email?.label?.value}
              name="email"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              onBlur={validateEmail}
              isInvalid={emailInvalid}
              required
              autoComplete=""
            >
              {emailInvalid && (
                <ErrorMessage id="email-invalid-error">
                  <Tmnt tmnt={emailInvalidText} isHtml />
                  {' '}
                  <Tmnt tmnt={emailInvalidFormatText} isHtml />
                </ErrorMessage>
              )}
            </InputField>
            <PasswordField>
              <InputField
                label={passwordLabel?.label?.value}
                labelProp={passwordLabel?.label?.value}
                id="recognizeCustomerForm_password"
                type={showPassword ? 'text' : 'password'}
                value={passwordValue || ''}
                onChange={(e) => setPasswordValue(e.target.value)}
                name="password"
                describedBy="password"
                isInvalid={passwordInvalid}
                autoComplete=""
              >
                <Button onClick={handleShowPassword}>
                  <Icon
                    icon={showPassword ? 'eye-filled' : 'eye'}
                    labelText={showPassword ? passwordShow?.value || '' : passwordHide?.value || ''}
                  />
                </Button>
              </InputField>

              {passwordInvalid && (
                <ErrorMessage id="email-invalid-error">
                  <Tmnt tmnt={passwordInvalidText} isHtml />
                </ErrorMessage>
              )}
            </PasswordField>
          </FieldGroup>
          <button
            className="js-forgot-password__link forgot-password link-button"
            data-variant="button-as-link"
            type="button"
          >
            <Tmnt tmnt={forgotPassword} isHtml />
          </button>
          <div className="keep-me-signed-in-checkbox-container">
            <Checkbox
              description={(
                <div className="signedIn-checkbox-content">
                  <span className="h4">
                    <Tmnt tmnt={keepMeSignedInCheckBox} isHtml />
                  </span>
                  <br />
                  <Tmnt tmnt={keepMeSignedInUncheckMsg} isHtml />
                </div>
              )}
              id="keepMeSignedIn"
              name="keepMeSignedIn"
              onChange={() => { setKeepMeSigned(!keepMeSignedIn); }}
              isChecked={keepMeSignedIn}
              data-testid="keepMeSignedIn"
            />
          </div>
          <div hidden={!displayFormError}>
            <ErrorMessage id="recognizeCustomerForm__errorMessage">
              {formErrorText}
            </ErrorMessage>
          </div>
          <Button
            classList="btn-signin first"
            isDisabled={submitButtonDisabledStyle}
            leftIcon={submitButtonLeftIcon}
            isProcessing={submitButtonLeftIcon === 'spinner'}
            variant="secondary"
            type="submit"
            isFullWidth
          >
            {submitButtonLeftIcon === 'check' && (
            <span className="font-icon">
              <Icon icon="check" />
            </span>
            )}
            <span>{submitButtonLabel || signInButton?.value}</span>
          </Button>
          <div className="join-link-container-account">
            <Tmnt tmnt={dontHaveAnAccount} isHtml />
            <button
              className="link-button"
              data-theme="default"
              type="button"
              data-variant="button-as-link"
              onClick={handleJoinMyAbercrombieClick}
            >
              <b>
                <Tmnt tmnt={join} isHtml />
              </b>
            </button>
          </div>
          <div className="horizontal-or-separator">
            <Tmnt tmnt={orText} isHtml />
          </div>
          <Button
            classList="tertiary-button btn-continue-guest"
            variant=""
            type="button"
            isFullWidth
            onClick={continueAsGuest}
          >
            <Tmnt tmnt={continueAsGuestButton} isHtml />
          </Button>
        </form>
      </div>
    </div>
  );
}

RecognizeCustomerForm.defaultProps = {
  tmntData: {},
};

RecognizeCustomerForm.propTypes = {
  unmount: PropTypes.func.isRequired,
  tmntData: PropTypes.instanceOf(Object),
};
