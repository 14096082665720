import React, { useContext, useRef, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  SingleEntryForm,
  InputField,
  Button,
  ErrorMessage,
  LegalText,
} from 'anf-core-react';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import isValidEmail from '../../tools/isValidEmail';
import LegalModal from '../Common/LegalModalBlock/LegalModal';
import { ModalContext } from '../../context/ModalContext/ModalContextProvider';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { textPairFragment } from '../../query/fragments';
import FooterEmailSubscribeModal from './FooterEmailSubscribeModal';
import useLegalLinksEvent from '../../hooks/useLegalLinksEvents';
import LegalModalButton from '../Common/LegalModalButton/LegalModalButton';

const FOOTER_EMAIL_SUBSCRIBE_FORM_QUERY = gql`
  query FOOTER_EMAIL_SUBSCRIBE_FORM_QUERY {
    config {
      brand: string(name: "brand")
    }
    isMFELegalModalEnabled: featureFlag(
      key: "has-mfe-legal-modals-enabled",
      defaultValue: false
    )
    textFor {
      formHeader: pair(pairKey: "footerEmailSubscribeFormHeader") {...textPair}
      formError: pair(pairKey: "footerEmailSubscribeFormError") {...textPair}
      joinButtonText: pair(pairKey: "footerEmailSubscribeFormJoinButtonText") {...textPair}
      formInputLabelText: pair(pairKey: "footerEmailSubscribeFormInputText") {...textPair}
      legal: legal(fieldKey: "footerEmailSubscribe") {
        text {...textPair}
        check
      }
    }
  }

  ${textPairFragment}
`;

export default function FooterEmailSubscribeForm() {
  const {
    loading, error: queryError, data: queryData,
  } = useQuery(FOOTER_EMAIL_SUBSCRIBE_FORM_QUERY);

  const [emailInput, setEmailInput] = useState('');
  const [emailInputInvalidErrMsg, setEmailInputInvalidErrMsg] = useState('');
  const [isFooterSubscribeModalOpen, setIsFooterSubscribeModalOpen] = useState(false);
  const inputId = 'email-subscribe-email-field';

  const validateEmail = () => {
    const isValid = isValidEmail(emailInput);
    if (!isValid) setEmailInputInvalidErrMsg(queryData.textFor.formError?.value);
    return isValid;
  };

  const dispatchSubscribeModalEvent = () => { setIsFooterSubscribeModalOpen(true); };

  const handleOnSubmit = () => {
    setEmailInputInvalidErrMsg('');
    const isSubmissionValid = validateEmail();
    if (isSubmissionValid) dispatchSubscribeModalEvent();
  };

  const [currentLegalModal, setCurrentLegalModal] = useState(null);
  const { isModalOpen } = useContext(ModalContext);
  const legalBtnRef = useRef(null);
  useLegalLinksEvent(legalBtnRef, (val) => {
    setCurrentLegalModal(val);
  });

  if (loading) return null;
  if (queryError) return ERROR_MESSAGE;

  const { brand } = queryData.config;

  const {
    formHeader,
    joinButtonText,
    formInputLabelText,
    legal,
  } = queryData.textFor;

  const { isMFELegalModalEnabled } = queryData;

  const isKids = brand === 'kids';

  return (
    <>
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <h2
              className="catalog-footer-module__linkHeading"
              data-text-key={formHeader?.key}
            >
              { formHeader?.value }
            </h2>
          </FormCell>

          <FormCell>
            <SingleEntryForm>
              <InputField
                autoComplete="email"
                id={inputId}
                label={formInputLabelText?.value}
                isInvalid={!!emailInputInvalidErrMsg}
                onChange={(e) => setEmailInput(e.target.value)}
                isRequired
                value={emailInput}
              >
                <Button
                  onClick={handleOnSubmit}
                  theme={!isKids ? null : 'inverse'}
                  variant={!isKids ? 'secondary' : 'tertiary-light'}
                >
                  <TmntText tmnt={joinButtonText} />
                </Button>
              </InputField>
            </SingleEntryForm>
            {
              emailInputInvalidErrMsg && (
                <ErrorMessage
                  id="footerEmailSubscribeForm_errorMessage"
                  variant="error"
                >
                  {emailInputInvalidErrMsg}
                </ErrorMessage>
              )
            }
          </FormCell>
          <FormCell>
            {legal.text.key && (
              isMFELegalModalEnabled
                ? <LegalModalButton ref={legalBtnRef} text={legal.text} />
                : (
                  <LegalText>
                    <TmntText tmnt={legal.text} />
                  </LegalText>
                )
            )}

            { isModalOpen && legal.text.key
              ? <LegalModal legalText={currentLegalModal} />
              : null}

          </FormCell>
        </FormGroup>
      </FormWrapper>

      <FooterEmailSubscribeModal
        email={emailInput}
        isOpen={isFooterSubscribeModalOpen}
        onClose={() => setIsFooterSubscribeModalOpen(false)}
      />
    </>
  );
}
