import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import NOTIFICATION_DATA_QUERY from '../../../gql/notification.gql';
import SwitchTestContext from '../../../context/SwitchTestContext';

function UserNotifications({
  message, notificationKey, className, onNotificationCheck,
}) {
  const { digitalData } = useContext(SwitchTestContext);
  const [notification, setNotification] = useState(message?.value || null);
  const [sessionStorageKey] = useState(notificationKey);
  const hasNotification = digitalData && digitalData['chk-has-two-x-birthday-notification'];

  // Only query when message is not provided and no cached notification exissts in session storage
  const { data: notificationData } = useQuery(NOTIFICATION_DATA_QUERY, {
    skip: !!message?.value || !notificationKey || !!sessionStorage.getItem(notificationKey),
    context: { batch: true },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (notificationData?.userData?.userNotifications?.value && sessionStorageKey) {
      const fetchedNotification = notificationData.userData.userNotifications.value;
      sessionStorage.setItem(sessionStorageKey, fetchedNotification);
      setNotification(fetchedNotification);
      onNotificationCheck(fetchedNotification);
    }
  }, [notificationData, sessionStorageKey, onNotificationCheck]);

  useEffect(() => {
    if (!message?.value && notificationKey) {
      const cachedNotification = sessionStorage.getItem(notificationKey);
      if (cachedNotification) {
        setNotification(cachedNotification);
        onNotificationCheck(cachedNotification);
      }
    }
  }, [message, notificationKey, onNotificationCheck]);

  if (!notification || !hasNotification) {
    return null;
  }

  return (
    <div
      data-property="GLB_BIRTHDAY_NOTIFICATION_MINIBAG"
      className={`notification-banner ${className}`}
    >
      {notification}
    </div>
  );
}

UserNotifications.propTypes = {
  message: PropTypes.shape({
    value: PropTypes.string,
  }),
  notificationKey: PropTypes.string,
  className: PropTypes.string,
  onNotificationCheck: PropTypes.func,
};

UserNotifications.defaultProps = {
  message: {},
  notificationKey: null,
  className: '',
  onNotificationCheck: () => {},
};

export default UserNotifications;
