import React from 'react';
import PropTypes from 'prop-types';
import Tmnt from '../../../Tmnt/Tmnt';
import SignInJoinButton from '../../SignInJoinButton/SignInJoinButton';

export default function LoggedOutView({
  tmntData,
  estimatedPoints,
  iconImageUrl,
  displayIconImage,
  isEmptyBag,
}) {
  const sessionKey = tmntData?.sessionText?.key;
  let sessionValue = tmntData?.sessionText?.value;
  sessionValue = sessionValue.replace('{0}', estimatedPoints);
  const sessionTmntObject = {
    key: sessionKey,
    value: sessionValue,
  };
  const logo = displayIconImage ? 'logo-myabercrombie' : '';

  return (
    <div className="session">
      <div className="icon-heading ds-override" data-layout="horizontal" data-theme="">
        <div className="icon-heading-icon">
          {displayIconImage && <span className="icon-block-icon" data-icon={logo} aria-label={logo} />}
          {!displayIconImage
              && (
              <span className="icon-block-icon" data-icon={tmntData?.loyaltyLogoLabel?.value} aria-label={tmntData?.loyaltyLogoLabel?.value}>
                <img className="icon-block-icon-image" src={iconImageUrl} alt={tmntData?.account?.value} />
              </span>
              )}
          <div className="icon-heading-text">
            <span data-testid="iconHeadingSession">
              { !isEmptyBag
                ? <Tmnt tmnt={sessionTmntObject} isHtml />
                : (
                  <Tmnt tmnt={tmntData?.emptyBagLoggedOut} isHtml />
                )}
            </span>
            <span data-testid="iconHeadingReward" className="iconHeadingReward">
              <Tmnt tmnt={tmntData?.getRewarded} isHtml />
            </span>
          </div>
        </div>
        <SignInJoinButton tmntData={tmntData} isEmptyBag={isEmptyBag} />
      </div>
    </div>
  );
}

LoggedOutView.defaultProps = {
  tmntData: {},
  estimatedPoints: '0',
  iconImageUrl: '',
  displayIconImage: false,
  isEmptyBag: false,

};

LoggedOutView.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  estimatedPoints: PropTypes.string,
  iconImageUrl: PropTypes.string,
  displayIconImage: PropTypes.bool,
  isEmptyBag: PropTypes.bool,
};
