/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
// import { useDatabridgeSubscribe } from '@xp-utilities/web';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { ERROR_MESSAGE } from '../Messages/Messages';
import GiftCardBalanceForm from './GiftCardBalanceForm';
import OrderTrackingForm from './OrderTrackingForm';
import ViewOrderHistoryButton from './ViewOrderHistoryButton';
import { ORDERHELP_SELFSERVICE_PAGE_QUERY } from './operations';
import AdditionalInfo from './AdditionalInfo';

export default function SelfServiceOrderHelpPages() {
  const helpPage = window.digitalData.get('page');
  const pageName = helpPage.pageName.split(':')[1];

  const { data: queryData, loading: queryLoading, error } = useQuery(
    ORDERHELP_SELFSERVICE_PAGE_QUERY,
  );
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('pagefm', 'navigation - self service');
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
  }, [pageName]);

  if (queryLoading) return null;
  if (error) return ERROR_MESSAGE;

  const {
    textFor,
    userState = {},
    config = {},
    userCountry,
  } = queryData || {};
  const {
    trackMyOrder,
    startMyReturn,
    trackMyReturn,
    lookUpMyOrder,
    checkGiftCardBalance,
    viewOrderDetailsText,
    orderNumberText,
    emailAddressText,
    returnOrderNumberText,
    returnOrderNumberTooltipText,
    orderNumberTooltipText,
    fromOrderHistoryText,
    signInToViewOrderHistoryText,
    viewOrderHistory,
    additionalInfoText,
    trackMyOrderAdditionalInfoText,
    returnsAdditionalInfoText,
    giftCardAdditionalInfo,
  } = textFor || {};

  const { isLoggedIn = false } = userState;
  const { enableMFEAuthenticationModal, userOrderHistoryDisplayUrl } = config || {};

  const helpPageTitle = (() => {
    switch (pageName) {
      case 'helpTrackMyOrder':
        return trackMyOrder?.value;
      case 'startmyreturn':
        return startMyReturn?.value;
      case 'trackMyReturn':
        return trackMyReturn?.value;
      case 'helpLookUpMyOrder':
        return lookUpMyOrder?.value;
      case 'giftCardBalanceCheck':
        return checkGiftCardBalance?.value;
      default:
        return '';
    }
  })();

  const getPageButtonText = (page) => {
    switch (page) {
      case 'helpTrackMyOrder':
        return trackMyOrder?.value;
      case 'startmyreturn':
        return startMyReturn?.value;
      case 'helpLookUpMyOrder':
        return viewOrderDetailsText?.value;
      default:
        return '';
    }
  };

  const renderForm = () => {
    const commonProps = {
      orderNumberText: orderNumberText?.value,
      emailAddressText: emailAddressText?.value,
      orderNumberTooltipText,
      toolTipLabel: orderNumberTooltipText?.value,
    };
    switch (pageName) {
      case 'helpTrackMyOrder':
      case 'startmyreturn':
      case 'helpLookUpMyOrder':
        return (
          <OrderTrackingForm
            orderHelpButtonText={getPageButtonText(pageName)}
            {...commonProps}
          />
        );
      case 'trackMyReturn':
        return (
          <OrderTrackingForm
            orderHelpButtonText={trackMyReturn?.value}
            orderNumberText={returnOrderNumberText?.value}
            emailAddressText={emailAddressText?.value}
            orderNumberTooltipText={returnOrderNumberTooltipText}
            toolTipLabel={returnOrderNumberTooltipText?.value}
          />
        );
      case 'giftCardBalanceCheck':
        return <GiftCardBalanceForm />;
      default:
        return null;
    }
  };

  const SectionContent = (() => {
    switch (pageName) {
      case 'helpTrackMyOrder':
        return trackMyOrderAdditionalInfoText;
      case 'startmyreturn':
        return returnsAdditionalInfoText;
      case 'trackMyReturn':
        return returnsAdditionalInfoText;
      case 'helpLookUpMyOrder':
        return '';
      case 'giftCardBalanceCheck':
        return giftCardAdditionalInfo;
      default:
        return null;
    }
  })();
  return (
    <AccountBlock
      heading={(
        <SectionHeading>
          <h1 className="h1" data-testid={`help-page-section-title-${pageName}`}>
            {helpPageTitle}
          </h1>
        </SectionHeading>
      )}
      classList="self-service-account-block"
    >
      {pageName !== 'giftCardBalanceCheck' && (
        <div
          className="form-help-container view-order-history"
          data-testid={`view-order-history-container-${pageName}`}
        >
          <ViewOrderHistoryButton
            viewOrderHistory={viewOrderHistory}
            signInToViewOrderHistoryText={signInToViewOrderHistoryText}
            fromOrderHistoryText={fromOrderHistoryText}
            isLoggedIn={isLoggedIn}
            enableMFEAuthenticationModal={enableMFEAuthenticationModal}
            userOrderHistoryDisplayUrl={userOrderHistoryDisplayUrl}
          />
        </div>
      )}
      <div
        className="form-help-container"
        data-testid={`help-form-container-${pageName}`}
      >
        {renderForm()}
      </div>
      {SectionContent && (
        <>
          <hr />
          <AdditionalInfo
            userCountry={userCountry || ''}
            sectionTitle={additionalInfoText}
            sectionContent={SectionContent}
          />
        </>
      )}
    </AccountBlock>
  );
}
