import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { Toaster } from 'anf-core-react';
import $window from '../../tools/window';
import BagList from '../Common/BagList/BagList';
import FreeShipping from '../Common/FreeShipping/FreeShipping';
import BagCta from '../Common/BagCta/BagCta';
import NotificationComponent from '../Common/UserNotiications/UserNotifications';
import {
  ERROR_MESSAGE as errorMessage,
} from '../Common/Messages/Messages';
import { MINIBAG_DATA_QUERY, REMOVE_BAG_ITEM_MUTATION } from '../../gql/bagItem.gql';
import decodeHTMLEntities from '../../tools/decodeHtml';
import Loader from '../Common/Loader/Loader';
import useLog from '../useLog/useLog';
import useDebug from '../../hooks/useDebug/useDebug';
import trackAction from '../../tools/analytics';
import Tmnt from '../Tmnt/Tmnt';
import MiniBagInstrumentedRecommendations from '../Recommendations/MiniBagInstrumentedRecommendations';

const toasterId = 'miniBag-toaster';

function ToasterHeader({ queryData, miniBagState }) {
  const bagItemsLength = miniBagState?.bagItems?.items?.length || 0;

  const itemLabel = bagItemsLength === 1
    ? queryData.textFor.itemLabel.value : queryData.textFor.itemsLabel.value;
  const isEmptyBag = (bagItemsLength === 0);
  const miniBagHeaderValue = queryData?.textFor?.miniBagHeader?.value && queryData.textFor.miniBagHeader.value !== '??????'
    ? queryData?.textFor?.miniBagHeader
    : { key: 'GLB_MINI_BAG_HEADER', value: 'Shopping Bag' };
  return (
    <div className="checkout-mfe-web-service scope-1892" data-empty-bag={isEmptyBag}>
      <span className="h2 headline" data-testid="mini-bag-heading">
        <Tmnt tmnt={miniBagHeaderValue} isHtml />
        &nbsp;
        (
        {bagItemsLength}
        {' '}
        {itemLabel}
        )
      </span>
    </div>
  );
}

ToasterHeader.defaultProps = {
  queryData: {},
  miniBagState: {},
};

ToasterHeader.propTypes = {
  queryData: PropTypes.instanceOf(Object),
  miniBagState: PropTypes.instanceOf(Object),
};

function ToasterFooter({
  queryData, miniBagState, viewBagProps, standaloneComponent,
}) {
  const {
    isVisible,
    progressValue,
    amountToFreeShippingFmt,
  } = miniBagState?.freeShippingProgressInfo || {};
  const bottomDivRef = useRef(null);

  useEffect(() => {
    const bottomDiv = bottomDivRef.current;
    if (bottomDiv) {
      const root = document.documentElement;
      root.style.setProperty('--mini-bag-bottom-height', `${bottomDiv.offsetHeight}px`);
    }
  }, [isVisible, amountToFreeShippingFmt, progressValue]);

  const bagItemsLength = miniBagState?.bagItems?.items?.length || 0;
  const isEmptyBag = (bagItemsLength === 0);
  return (
    <div className="toaster-footer scope-1892" data-empty-bag={isEmptyBag} ref={bottomDivRef}>
      <div className="order-total-summary h3">
        <p>{queryData.textFor.subtotal.value}</p>
        <p>{decodeHTMLEntities(miniBagState?.orderTotals?.subTotalFmt)}</p>
      </div>
      <div className="free-shipping-container">
        <FreeShipping {...miniBagState?.freeShippingProgressInfo} tmntData={queryData.textFor} />
      </div>
      <div className="mini-bag-ctas" data-testid="mini-bag-ctas">
        {
        standaloneComponent
          || (
          <BagCta
          // checkoutURLProps={queryData.checkoutButton}
            viewBagProps={viewBagProps}
            tmntData={queryData.textFor}
            showCheckoutBtn={false}
          />
          )
        }
      </div>
    </div>
  );
}

ToasterFooter.defaultProps = {
  queryData: {},
  miniBagState: {},
  viewBagProps: {},
  standaloneComponent: undefined,
};

ToasterFooter.propTypes = {
  queryData: PropTypes.instanceOf(Object),
  miniBagState: PropTypes.instanceOf(Object),
  viewBagProps: PropTypes.instanceOf(Object),
  standaloneComponent: PropTypes.instanceOf(Object),
};

export default function MiniBag({ standaloneComponent }) {
  // check the debug mode of the App
  const debugMode = useDebug();
  const [isOpen, setOpen] = useState();
  const [miniBagState, setMiniBagState] = useState();
  const logger = useLog('checkout.minibag');
  const listSectionRef = useRef(null);
  const [notificationPresent, setNotification] = useState(false);
  const [loadMiniBagQuery, {
    loading, error: queryError, data: queryData,
  }] = useLazyQuery(MINIBAG_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
  });

  const handleNotificationCheck = (notification) => {
    setNotification(!!notification);
  };

  useEffect(() => {
    window.addEventListener(`${toasterId}:open`, (event) => {
      event.stopPropagation();
      loadMiniBagQuery().then((response) => {
        if ((response && response?.data && response?.data?.bagItems)) {
          setMiniBagState(response?.data);
          const updateItemCount = new CustomEvent('miniBag:updateItemCount:done', { detail: response?.data?.bagItems });
          window.dispatchEvent(updateItemCount);
          // merge the mini bag response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: 'miniBag',
            data: response?.data,
            action: 'load',
          });
        }
      });

      setOpen(true);
    });
    window.addEventListener(`${toasterId}:close`, (event) => {
      // close mini bag toaster
      setOpen(false);
      // if the event detail is passed, means we need to initialize QV
      if (event.detail) {
        // adding timeout for init QV because it solves timing issue of
        // closing toaster and opening QV modal
        // using 500ms as a temp testing value, may need to be updated later
        setTimeout(() => {
          const initQVEvent = new CustomEvent('initializeQuickViewFromMiniBag');
          initQVEvent.quickViewLinkAttrs = event.detail;
          window.dispatchEvent(initQVEvent);
        }, 500);
      }
    });
  }, [loadMiniBagQuery]);

  useEffect(() => {
    // If the switch is ON, then enable Key events to scroll through minibag
    const handleKeyDown = (event) => {
      if (!isOpen) return;
      const listSection = listSectionRef?.current;
      if (!listSection) return;
      if (event.key === 'ArrowUp') {
        listSection.scrollTop -= 50;
      } else if (event.key === 'ArrowDown') {
        listSection.scrollTop += 50;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const [removeBagItemMutation] = useMutation(REMOVE_BAG_ITEM_MUTATION, {
    onCompleted: ({ removeBagItem }) => {
      if ((removeBagItem && removeBagItem?.bagItems)) {
        logger.debug(`REMOVE_BAG_ITEM_MUTATION: ${removeBagItem}`);
        setMiniBagState(removeBagItem);
        const updateItemCount = new CustomEvent('miniBag:updateItemCount:done', { detail: removeBagItem?.bagItems });
        window.dispatchEvent(updateItemCount);

        // merge the mini bag response to digitalData `cart`
        $window.digitalData?.merge('cart', {
          origin: 'miniBag',
          data: removeBagItem,
          action: 'remove',
        });
        window.digitalData.set('cart.data.bagItems', removeBagItem?.bagItems);
      }
    },
    onError: (err) => {
      logger.error(`ERR: REMOVE_BAG_ITEM_MUTATION: ${JSON.stringify(err)}`);
    },
  });
  const removeBagItem = async (data) => {
    try {
      await removeBagItemMutation({
        variables: {
          orderItemId: data?.dataset?.orderitemid,
        },
      });

      const itemToRemove = {
        name: 'cart item removed',
        collectionId: data?.dataset?.collection,
        longSku: data?.dataset?.longsku,
        shortSku: data?.dataset?.sku,
        inventoryAvailability: data?.dataset?.inventoryavailability,
        kic: data?.dataset?.kicid,
        removeFromCart: {
          event_name: 'remove_from_cart',
          event_type: 'order',
          page_description: 'mini bag',
          page_view_method: 'page load',
          collection_id: data?.dataset?.collection,
          long_sku: data?.dataset?.longsku,
          brand: data?.dataset?.brand,
          product_name: data?.dataset?.name,
          key_item_color: data?.dataset?.kicid,
          price_flag: data?.dataset?.priceflag,
          price_list_local: data?.dataset?.listprice,
          price_list_usd: data?.dataset?.listpriceusd,
          price_offer_local: data?.dataset?.offerprice,
          price_offer_usd: data?.dataset?.offerpriceusd,
        },
      };
      // trigger analytics event
      trackAction('miniBag.cartItemRemoved', itemToRemove);
    } catch (err) {
      logger.error(`ERR: REMOVE_BAG_ITEM_MUTATION: ${JSON.stringify(err)}`);
    }
  };
  if (loading) return <Loader classList="loader-mini-bag" />;
  if (queryError) return errorMessage;
  if (!queryData) return null;
  const viewBagProps = { ...queryData.viewBagButton };
  viewBagProps.label = queryData.textFor.viewBag.value;

  const bagItemsLength = miniBagState?.bagItems?.items?.length || 0;
  const isEmptyBag = (bagItemsLength === 0);
  const bagListSectionHtml = () => (
    <div data-testid="list-section" data-empty-bag={isEmptyBag} className={`list-section ${notificationPresent ? 'notification' : ''}`} ref={listSectionRef}>
      <BagList
        variant="mini"
        bagItems={miniBagState?.bagItems?.items || []}
        removeBagItem={removeBagItem}
        tmntData={queryData.textFor}
        showShopSimilarItems={false}
      />
    </div>
  );

  const recommendationsProps = {
    id: 'miniBag-1',
    placements: [
      'cart_page.mini_bag1',
      'cart_page.mini_bag2',
      'cart_page.mini_bag3',
      'cart_page.mini_bag4',
      'cart_page.mini_bag5',
      'cart_page.mini_bag6',
    ],
  };

  return (
    <Toaster
      closeButtonLabel={queryData.textFor.closeButtonTMNTValue.value}
      direction="from-right"
      id={toasterId}
      isOpen={isOpen}
      // disable on mouse leave if debug mode is ON
      onMouseLeave={() => !debugMode && window.dispatchEvent(new Event(`${toasterId}:close`))}
      isSticky
      onClose={() => window.dispatchEvent(new Event(`${toasterId}:close`))}
      heading={(
        <>
          <ToasterHeader
            queryData={queryData}
            miniBagState={miniBagState}
          />
          <div className="notification-container"><NotificationComponent className="notification-component" onNotificationCheck={handleNotificationCheck} notificationKey={queryData?.userNotificationsKey} /></div>
        </>
      )}
      footer={(
        <ToasterFooter
          queryData={queryData}
          miniBagState={miniBagState}
          viewBagProps={viewBagProps}
          standaloneComponent={standaloneComponent}
        />
      )}
    >
      {bagListSectionHtml()}

      {isOpen
      && (
      <MiniBagInstrumentedRecommendations
        id={recommendationsProps.id}
        placements={recommendationsProps.placements}
        miniBagData={miniBagState}
      />
      )}

    </Toaster>
  );
}

MiniBag.defaultProps = {
  freeShippingItems: {
    isVisible: false,
    amountToFreeShipping: 0,
    freeShippingAmountTMNTKey: '',
    freeShippingEarnedTMNTKey: '',
    freeShippingEarnedTMNTValue: '',
    freeShippingAmountTMNTValue: '',
    progressValue: 0,
    maxProgress: 0,
    minProgress: 0,
    postLabel: '',
    preLabel: '',
  },
  checkoutURLProps: {
    checkoutUrl: '',
    brand: '',
    variant: '',
    checkoutBtnTMNTValue: '',
    takingYouToCheckout: '',
  },
  viewBagProps: {
    bagUrl: '',
    brand: '',
    variant: '',
    label: '',
  },
  standaloneComponent: undefined,
};

MiniBag.propTypes = {
  freeShippingItems: PropTypes.shape({
    isVisible: PropTypes.bool,
    amountToFreeShipping: PropTypes.number,
    freeShippingAmountTMNTKey: PropTypes.string,
    freeShippingEarnedTMNTKey: PropTypes.string,
    freeShippingEarnedTMNTValue: PropTypes.string,
    freeShippingAmountTMNTValue: PropTypes.string,
    progressValue: PropTypes.number,
    maxProgress: PropTypes.number,
    minProgress: PropTypes.number,
    postLabel: PropTypes.string,
    preLabel: PropTypes.string,
  }),
  checkoutURLProps: PropTypes.shape({
    checkoutUrl: PropTypes.string,
    brand: PropTypes.string,
    variant: PropTypes.string,
    checkoutBtnTMNTValue: PropTypes.string,
    takingYouToCheckout: PropTypes.string,
  }),
  viewBagProps: PropTypes.shape({
    bagUrl: PropTypes.string,
    brand: PropTypes.string,
    variant: PropTypes.string,
    label: PropTypes.string,
  }),
  standaloneComponent: PropTypes.instanceOf(Object),
};
