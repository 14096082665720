import { gql, useQuery } from '@apollo/client';
import Logo from 'anf-core-react/components/Logo';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import getMyListLink from '../../tools/getMyListLink';
import BreakpointProvider from '../BreakpointProvider/BreakpointProvider';
import DigitalDataProvider, { DD_SHOW_ANF_NAVIGATION_L3S_TEST } from '../DigitalDataProvider';
import GmsEspotBanner from '../GmsEspotBanner/GmsEspotBanner';
import MyListButton from '../Header/MyListButton';
import MyListNotifications from '../Header/MyListNotifications';
import SearchDataProvider from '../SearchBox/SearchDataProvider';
import inputStyle from '../SearchBox/fakeSearchInputField.module.scss';
import Nav from '../SmallScreenNav';
import StandaloneMfe from '../StandaloneMfe/StandaloneMfe';
import style from './SmallScreenNavBar.module.scss';
import SmallScreenNavMenu from './SmallScreenNavMenu';

const getData = gql`
  query SmallScreenNavBarData($categoryId: String!) {
    category(categoryId: $categoryId) {
      brandLogoType
      url
    }
    config {
      assistiveSearchNav: nonCachedGenericType(name: "assistiveSearchNav")
      elevatedAccountInMobileNav: genericType(name: "elevatedAccountInMobileNav")
    }
  }
`;

const SmallScreenNavBar = ({
  brand,
  categoryId,
  country: defaultCountry = '',
  catalogId,
  langId,
  storeId,
  urlRoot,
}) => {
  const targetRef = useRef();
  const myListLink = getMyListLink(storeId, catalogId, langId);
  const openMenu = useTranslatedText('openMenu', { fallback: 'Open Menu' });
  const openMyList = useTranslatedText('openMyList', { fallback: 'View My List' });
  const { data } = useQuery(getData, {
    variables: {
      categoryId,
    },
  });
  const { config } = data || {};
  const {
    assistiveSearchNav: { value: newSearchExperience } = {},
    elevatedAccountInMobileNav: { value: isElevatedAccountEnabled } = {},
  } = config || {};
  const [navOpen, setNavOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [firstTimeSave, setFirstTimeSave] = useState(false);
  const handleNavToggle = () => setNavOpen((navState) => !navState);

  useEffect(() => {
    /* Other components still in CRS likely need this class to know when the nav is open */
    const { body } = document;
    if (navOpen) {
      body.classList.add('rs-nav--open');
      const event = new Event('render-signin-block');
      document.dispatchEvent(event);
    } else {
      body.classList.remove('rs-nav--open');
    }
  }, [navOpen]);

  useEffect(() => {
    const handleMySaveSuccess = (event) => {
      setShowNotification(true);
      setFirstTimeSave(event.detail?.isFirstTimeSave);
      setTimeout(() => {
        setShowNotification(false);
        setFirstTimeSave(false);
      }, 8000);
    };

    window.addEventListener('crs:mySaveSuccess', handleMySaveSuccess);

    return () => {
      window.removeEventListener('crs:mySaveSuccess', handleMySaveSuccess);
    };
  }, []);

  const renderMyList = () => (
    <MyListButton
      isActive={showNotification}
      label={openMyList.value}
      myListLink={myListLink}
    />
  );
  const renderSearch = () => <SearchDataProvider brand={brand} country={defaultCountry} searchUrl={`${urlRoot}/search`} />;

  const renderSecondItem = () => {
    if (!isElevatedAccountEnabled) return renderMyList();
    return newSearchExperience ? null : renderSearch();
  };
  return (
    <DigitalDataProvider
      keys={[
        DD_SHOW_ANF_NAVIGATION_L3S_TEST,
      ]}
    >
      <BreakpointProvider>
        <div className={classNames('scope-1892', style.wrapper)}>
          <div ref={targetRef} />
          <div className={style.stickyWrapper}>
            <div className={style.stickyHeaderWrapper}>
              <header
                className={classNames(
                  style.navBar,
                )}
              >
                {/* Left-aligned icons */}
                <div className={style.left}>
                  <div
                    className={style.toggle}
                    data-testid="open-nav-menu-btn"
                  >
                    {/* Hamburger menu */}
                    <SmallScreenNavMenu
                      labelText={openMenu.value}
                      onClick={handleNavToggle}
                    />
                  </div>
                  {/* Search Icon/Heart Icon for My List  */}
                  {renderSecondItem()}
                  {/* My Saves Notification  */}
                  {showNotification && (
                    <MyListNotifications
                      brand={brand}
                      createAccountLink={`/shop/AccountRouterView?storeId=${storeId}&isUserLogin=false&catalogId=${catalogId}&langId=${langId}`}
                      isFirstTimeSave={firstTimeSave}
                      myListLink={myListLink}
                      signInLink={`/shop/AccountRouterView?storeId=${storeId}&isUserLogin=true&catalogId=${catalogId}&langId=${langId}`}
                    />
                  )}
                </div>
                {/* Center-aligned logo */}
                {
                  !!data?.category
                    && (
                      <div className={style.center}>
                        <a aria-label={brand} className={style.logo} href={data.category.url}>
                          <Logo kind={data.category.brandLogoType} />
                        </a>
                      </div>
                    )
                }
                <div className={style.right}>
                  {/* placeholder container where MicroFrontend from xp-utils
                  will be used render the account menu client from customer mfe */}
                  {isElevatedAccountEnabled ? <section id="customer-mfe-mobile-account-client" /> : renderSearch()}
                  <StandaloneMfe componentName="miniBagMobile" mfeName="checkout" page="mini" ttl={0} />
                  <section id="checkout-mfe-mobile-minibag-portal" />
                </div>
              </header>
            </div>
            {newSearchExperience && (
              <div className={inputStyle.mobileInputContainer}>
                {renderSearch()}
              </div>
            )}
          </div>
          <Nav
            brand={brand}
            defaultCountry={defaultCountry}
            handleNavToggle={handleNavToggle}
            l0CategoryId={categoryId}
            navOpen={navOpen}
          />
        </div>
        <div className={style.gmsBanner}>
          <GmsEspotBanner brand={brand} />
        </div>
        <div id="main-content-start" />
      </BreakpointProvider>
    </DigitalDataProvider>
  );
};

SmallScreenNavBar.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  country: PropTypes.string,
  catalogId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  urlRoot: PropTypes.string.isRequired,
};

export default SmallScreenNavBar;
