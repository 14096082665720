import React, { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useQuery } from '@apollo/client';
import {
  Icon, IconBlock, Link as DSLink,
} from 'anf-core-react';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../../Messages/Messages';
import { TmntText } from '../Text';
import { LOGGED_IN_LEFT_NAV_QUERY } from './operations';

export default function LoggedInLeftNav() {
  const [isMobile, setIsMobile] = useState(false);
  const { data, loading, error } = useQuery(LOGGED_IN_LEFT_NAV_QUERY);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    const newIsMobile = windowWidth < 1025;
    if (newIsMobile !== isMobile) setIsMobile(newIsMobile);
  }, [windowWidth, isMobile]);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor, config } = data;

  const checkIfSelected = (tabUrl) => {
    const { pathname } = window.location;
    const isSelected = tabUrl.includes(pathname);
    return isSelected ? 'acct-tabs__link--selected' : '';
  };

  const renderLink = (link) => {
    const { text, url } = link;
    return (
      <DSLink
        classList={`acct-tabs__link ${checkIfSelected(url)}`}
        href={url}
      >
        <TmntText classNames="acct-tabs__link-text" tmnt={text} />
      </DSLink>
    );
  };

  return (
    <div
      className="acct-left-nav account-left-nav--logged-in"
      data-testid="user-nav"
    >
      <div className="acct-left-nav__content">
        <div className="acct-welcome-msg">
          <IconBlock
            orientation="vertical"
            icon={(
              <Icon
                icon={config.loyaltyTierImageSrc}
                isImage
                labelText={textFor.tierImageAltText}
                size="l"
              />
            )}
          >
            <div className="h1">
              <TmntText tmnt={textFor.welcomeMessageText} />
            </div>
          </IconBlock>
          <div className="acct-welcome-msg__join-date">
            <TmntText tmnt={textFor.memberSinceText} />
          </div>
        </div>
      </div>
      <div className="acct-left-nav__content acct-left-nav__content--full-width">
        {/* necessary for overflowing tabs scroll experience on mobile */}
        <div className="acct-left-nav__tabs-container">
          <nav className="acct-tabs" data-is-mobile={isMobile}>
            {textFor.navLinks.options.map(renderLink)}
          </nav>
        </div>
      </div>
    </div>
  );
}
