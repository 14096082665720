import React, {
  useEffect, useContext, useState, useCallback,
} from 'react';
import UserSectionDetails from '../Common/UserSectionDetails/UserSectionDetails';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import CheckoutPageHeader from './CheckoutPageHeader/CheckoutPageHeader';
import SwitchTestContext from '../../context/SwitchTestContext';
import $window from '../../tools/window';

export default function CheckoutLeftRail() {
  const {
    checkoutPageState,
    setCheckoutPageState,
    setShowDiscount,
    showNotification,
    setShowNotification,
  } = useContext(CheckoutPageContext);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const [mounted, setMounted] = useState(false);

  const loadCheckoutPageHeader = digitalData && digitalData['chk-has-checkout-page-header'];
  const hasApplePayPXP = digitalData && digitalData['chk-has-apple-pay-pxp'];
  const hasApplePayLD = digitalData && digitalData['chk-has-apple-pay'];
  const hasApplePay = checkoutPageState?.applePayButton?.hasApplePay
    && !!$window.ApplePaySession
    && hasApplePayLD;

  const updateCheckoutPageState = useCallback((newData) => {
    setCheckoutPageState((prev) => ({ ...prev, ...newData }));
  }, [setCheckoutPageState]);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) { return null; }

  return (
    <>
      {loadCheckoutPageHeader && (
      <CheckoutPageHeader
        tmntData={checkoutPageState?.textFor}
        hasSignInButton={checkoutPageState?.userData?.hasSignInButton}
        loadApplePay={hasApplePay}
        hasApplePayPXP={hasApplePayPXP}
        hasItemsInBag={checkoutPageState?.bagItems?.items?.length > 0}
        applePayData={checkoutPageState?.applePayButton}
        paypalData={checkoutPageState?.payPalExpressButton}
        orderTotals={checkoutPageState?.orderTotals}
        updateCheckoutPageState={updateCheckoutPageState}
        applePayConfig={checkoutPageState?.paymentConfig?.applePay ?? {}}
        countriesConfig={checkoutPageState?.orderSubmitLegalTerms?.countriesConfig}
        hasLoyaltyEnabled={checkoutPageState?.userData?.hasLoyaltyEnabled}
      />
      )}
      {/* promos-loyalty-wrapper start */}
      {checkoutPageState?.switches?.showCheckoutSignInModule && (
        <UserSectionDetails
          purchaseContext={checkoutPageState}
          setPurchaseContext={setCheckoutPageState}
          setShowDiscount={setShowDiscount}
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          page="checkout"
        />
      )}
      {/* promos-loyalty-wrapper end  */}
    </>
  );
}
