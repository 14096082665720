import { useQuery } from '@apollo/client';
import { GET_FEATURE_FLAG_QUERY, GET_FEATURE_FLAGS_QUERY } from '../gql/featureFlag.gql';

export const useFeatureFlag = (key, defaultValue = false, options = {}) => {
  const { data, loading, error } = useQuery(GET_FEATURE_FLAG_QUERY, {
    variables: { key, defaultValue },
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ...options,
  });

  const isFeatureFlagOn = data?.featureFlag?.isOn;

  return { isFeatureFlagOn, loading, error };
};

export const useFeatureFlags = (keysWithDefaultValue, options = {}) => {
  const { data, loading, error } = useQuery(GET_FEATURE_FLAGS_QUERY, {
    variables: { keysWithDefaultValue },
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ...options,
  });

  const flags = data?.featureFlag?.featureFlags?.flags?.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});

  return { flags, loading, error };
};
