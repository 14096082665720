import PropTypes from 'prop-types';
import React from 'react';
import {
  useLocalStorage,
} from 'react-use';
import useTranslatedText from '../../hooks/useTranslatedText';
import { RECENT_SEARCHES_KEY } from '../../tools/constants';
import removeFalsyItems from '../../tools/removeFalsyItems';
import $window from '../../tools/window';
import SearchDefaultList from './SearchDefaultList';
import listStyles from './SearchDefaultView.module.scss';
import useHelpTopics from './hooks/useHelpTopics';

export default function SearchDefaultView({
  children = null,
  isFormFocused = false,
  listItemSubmitHandler = () => {},
  popularSearchesList,
  wrapperId = '',
}) {
  const popularSearchHeader = useTranslatedText('popularSearchesHeader', { fallback: 'Popular Searches' });
  const recentSearchesLabel = useTranslatedText('recentSearchesLabel', { fallback: 'Recent Searches' });
  const searchLabel = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const helpLinksLabel = useTranslatedText('helpLinks', { fallback: 'Helpful Links' });

  const [recentSearchesList, setRecentSearchesList] = useLocalStorage(RECENT_SEARCHES_KEY, []);

  const helpLinksData = useHelpTopics();

  const onSubmit = ({ type, key, currentTarget }) => {
    const { dataset } = currentTarget || {};
    const { type: dataType } = dataset || {};

    if (type === 'click' || key === 'Enter') {
      listItemSubmitHandler(dataset, dataType);
    }
  };

  const handleHelpButtonClick = (event) => {
    const {
      currentTarget, target, type, key,
    } = event;
    if (type === 'click' || key === 'Enter') {
      if ($window.digitalData) {
        $window.digitalData.trigger('universal_click', {
          event_type: 'click',
          data_text: `Search-${target?.innerText}`,
          data_action: 'click',
        });
      }
      // Redirect to the user selected help topic
      window.location.href = currentTarget.dataset.url;
    }
  };

  const removeRecentSearches = () => {
    setRecentSearchesList([]);
  };

  const listData = [
    recentSearchesList.length > 0 && {
      clearAllButtonHandler: removeRecentSearches,
      dataType: 'recent search',
      hasClearAllButton: true,
      headingLabel: recentSearchesLabel?.value,
      options: recentSearchesList.map(
        (item) => ({
          key: item,
          title: item,
        }),
      ),
      eventHandler: onSubmit,
    },
    popularSearchesList.length > 0 && {
      dataType: 'popular search',
      headingLabel: popularSearchHeader?.value,
      options: popularSearchesList.map(
        (item) => ({
          key: item,
          title: item,
        }),
      ),
      eventHandler: onSubmit,
    },
    {
      dataType: 'help topics',
      headingLabel: helpLinksLabel?.value,
      options: helpLinksData?.helpLinks?.map(
        (item) => ({
          key: item.id,
          title: item.title,
          url: item.url,
        }),
      ),
      eventHandler: handleHelpButtonClick,
    },
  ];

  return (
    <div
      aria-label={searchLabel.value}
      className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyles.listWrapper}`}
      data-expanded={!!isFormFocused}
      data-focused={!!isFormFocused}
      id={wrapperId}
      role="listbox"
      tabIndex={-1}
    >
      {removeFalsyItems(listData).map((list) => (
        <SearchDefaultList
          key={list.dataType}
          clearAllButtonHandler={list.clearAllButtonHandler}
          dataType={list.dataType}
          eventHandler={list.eventHandler}
          hasClearAllButton={list.hasClearAllButton}
          headingLabel={list.headingLabel}
          options={list.options}
          wrapperId={wrapperId}
        />
      ))}
      {children}
    </div>
  );
}

SearchDefaultView.propTypes = {
  children: PropTypes.node,
  isFormFocused: PropTypes.bool,
  listItemSubmitHandler: PropTypes.func.isRequired,
  popularSearchesList: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  wrapperId: PropTypes.string,
};
