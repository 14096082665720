import React, { useContext } from 'react';
import { Icon } from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import TrackYourOrder from '../TrackYourOrder/TrackYourOrder';

function OrderInfo() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const {
    textFor, footer, shipMethod, bagItems, orderContact, orderInfo,
  } = orderConfirmationData;
  const isCodPayment = orderInfo?.isCodPayment;
  return (
    <section className="order-confirmation-hero-container" data-testid="order-confirmation-hero">
      <div className="order-confirmation-title">
        <Icon
          icon="checkmark-circle"
          labelText="labelText-Heart"
          size="l"
        />
        <h1 data-testid="order-confirmation-title">{textFor?.orderPlacedTMNTValue?.value}</h1>
      </div>
      <div className="order-confirmation-mfe-card">
        <span
          data-property={textFor?.orderNumberTMNTValue?.key}
          data-testid={`${textFor?.orderNumberTMNTValue?.key}-label`}
        >
          {textFor?.orderNumberTMNTValue?.value}
        </span>
        <span data-testid={`${textFor?.orderNumberTMNTValue?.key}-value`}>{footer?.orderId}</span>
        <div>
          <span
            data-property={textFor?.confirmationSentToTMNTValue?.key}
            data-testid={`${textFor?.confirmationSentToTMNTValue?.key}-label`}
          >
            {textFor?.confirmationSentToTMNTValue?.value}
          </span>
          <span className="order-confirmation-user-email" data-testid={`${textFor?.confirmationSentToTMNTValue?.key}-value`}>{orderContact?.email}</span>
        </div>
        {!bagItems?.hasOnlyEGiftCard
          ? (
            <div className="estimated-delivery-date">
              <span
                data-property={textFor?.estimatedDeliveryTMNTValue?.key}
                data-testid={`${textFor?.estimatedDeliveryTMNTValue?.key}-label`}
                className="eta"
              >
                {textFor?.estimatedDeliveryTMNTValue?.value}
              </span>
              <span data-testid={`${textFor?.estimatedDeliveryTMNTValue?.key}-value`}>{shipMethod?.estimatedDeliveryDateDisplay}</span>
              {shipMethod?.estimatedPreOrderShipDate
                && (
                <span data-property={textFor?.inStockItemsOnlyTMNT?.key}>
                  {' '}
                  {textFor?.inStockItemsOnlyTMNT?.value}
                </span>
                )}
            </div>
          ) : null}
      </div>
      {/* Load Track Your Order Component only it is Cash On Delivery */}
      {isCodPayment && <TrackYourOrder />}

    </section>
  );
}

export default OrderInfo;
