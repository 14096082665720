import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import trackAction from '../../../tools/analytics';
import Tmnt from '../../Tmnt/Tmnt';

function SignInJoinButton({ tmntData, isEmptyBag }) {
  const handleLoginClick = useCallback(() => {
    trackAction('bag_signin_click', {
      data_text: 'sign in or join',
      data_action: 'open',
      event_type: 'click',
    });
  }, []);

  const handleClick = (event) => {
    handleLoginClick(event);
    event.preventDefault();
    // Dispatch the custom event
    const customEvent = new CustomEvent('authentication-modal:open', {
      detail: { isSignIn: true }, // event.current.attribute.data('isUserLogin')
    });
    document.dispatchEvent(customEvent);
  };

  return (
    <div className="sign-in-join">
      <button
        data-testid="sign-in-trigger-button"
        className="button ds-override js-open-modal"
        onClick={handleClick}
        data-brand=""
        type="button"
        data-modal="authentication-modal"
        data-is-user-login="true"
        data-theme=""
        data-variant="secondary-button"
      >
        <span>
          <span className="screen-reader-text" />
          {!isEmptyBag ? (
            <Tmnt tmnt={tmntData?.signIn} isHtml />
          ) : (
            <Tmnt tmnt={tmntData?.emptyBagSignInButton} isHtml />
          )}
          <span className="screen-reader-text" />
        </span>
      </button>
    </div>
  );
}

SignInJoinButton.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  isEmptyBag: PropTypes.bool,
};

SignInJoinButton.defaultProps = {
  tmntData: {},
  isEmptyBag: false,
};

export default SignInJoinButton;
