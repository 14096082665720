import {
  gql,
} from '@apollo/client';

export const userExistsApi = gql`
  query userExists($email: String!) {
    userExists: userExists(email: $email)
  }
`;

export const userLoginApi = gql`
  mutation login($email: String!, $password: String!) {
    login: login(logonId: $email, password: $password)
  }
`;
