import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Icon,
  Logo,
  Toaster,
} from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import { useSearchParam } from 'react-use';
import useDigitalData from '../../hooks/useDigitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import DomNodePortal from '../../tools/DomNodePortal';
import {
  AUTOSUGGEST_THRESHOLD,
  PLACEMENT_IDS,
} from '../../tools/constants';
import getPageType from '../../tools/getPageType';
import $window from '../../tools/window';
import BreakpointProvider from '../BreakpointProvider';
import { DD_SEARCH_FLYOUT_RECS_MIGRATION } from '../DigitalDataProvider';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import Recommendations from '../Recommendations/Recommendations';
import SimilarEntryPoint from '../ShopSimilar/SimilarEntryPoint';
import FakeSearchInputField from './FakeSearchInputField';
import SearchBox from './SearchBox';
import inputStyle from './fakeSearchInputField.module.scss';
import style from './searchWrapper.module.scss';

const getStoreAttributeData = gql`
  query storeAttributes {
    config {
      hasMFEMobileNavToaster: genericType(name: "hasMFEMobileNavToaster")
      assistiveSearchNav: nonCachedGenericType(name: "assistiveSearchNav")
    }
  }
`;

// Need this for a DS bug where the onOpen event is called even when the toaster is closed
const handleToasterOpen = () => {
  if ($window.digitalData) {
    $window.digitalData.trigger('mfe-search-toaster-opened');
  }
};

const SearchWrapper = ({
  brand = '',
  searchUrl = '',
}) => {
  const betaFlagGroup = useSearchParam('group');
  const {
    [DD_SEARCH_FLYOUT_RECS_MIGRATION]: searchFlyoutRecs,
  } = useDigitalData([
    DD_SEARCH_FLYOUT_RECS_MIGRATION,
  ]);

  const [isSearchToasterOpen, setisSearchToasterOpen] = useState(false);
  const [showAlgonomyContainer, setShowAlgonomyContainer] = useState(true);

  const { data: storeAttributeData } = useQuery(getStoreAttributeData);

  const handleSearchIconClick = (event) => {
    if ($window.digitalData) {
      const pageType = getPageType();

      $window.digitalData.trigger(`${pageType}.search_icon.click`, {
        event_type: 'click',
        data_text: 'Search Icon',
        data_action: 'open',
      }, event);
    }
    setisSearchToasterOpen(true);
  };

  const handleOnProductClick = (event, product = {}) => {
    const {
      brand: productBrand,
      collection,
      faceout,
      id,
      imageId,
      name,
    } = product;

    const eventDetails = {
      productId: id,
      collectionId: collection,
      imageId,
      productName: name,
      productBrand,
      returnFocus: event.target,
      faceout,
    };

    // TODO: Add analtyics trigger
    const quickviewEvent = new CustomEvent('quickview:openModal', { detail: eventDetails });

    $window.dispatchEvent(quickviewEvent);
  };
  const newSearchExpierence = storeAttributeData?.config?.assistiveSearchNav?.value;
  const mobileMFENavEnabled = storeAttributeData?.config?.hasMFEMobileNavToaster?.value;

  const handleSearchTermChange = (searchTerm) => {
    setShowAlgonomyContainer(!(searchTerm.trim().length >= AUTOSUGGEST_THRESHOLD));
  };

  const searchToasterLabel = useTranslatedText('searchToasterLabel', { fallback: 'Open Search' });
  const autoSuggestDesktopPlaceholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const closeMenu = useTranslatedText('closeMenu', { fallback: 'Close' });
  const searchToasterButton = (
    <div
      className={classNames(
        style.wrapper,
        {
          [style.legacyStyles]: !mobileMFENavEnabled,
        },
      )}
      data-testid="search-toaster-button"
    >
      <Button
        classList={style.button}
        onClick={handleSearchIconClick}
        variant="borderless"
      >
        <Icon icon="search" labelText={searchToasterLabel.value} />
      </Button>
    </div>
  );
  const searchBox = (
    <SearchBox
      betaFlagGroup={betaFlagGroup}
      searchTermChange={handleSearchTermChange}
      searchUrl={searchUrl}
    />
  );

  const handleToasterClose = () => {
    setisSearchToasterOpen(false);
  };

  const toasterHeading = newSearchExpierence ? (
    <div className="scope-1892">
      <Logo kind={brand === 'hol' ? 'hco' : brand} />
    </div>
  ) : (
    <div className="scope-1892">
      <h2 className="headline">
        {autoSuggestDesktopPlaceholder.value}
      </h2>
    </div>
  );

  return (
    <>
      {(!mobileMFENavEnabled && !newSearchExpierence) && (
        <DomNodePortal targetNodeSelector=".rs-nav-bar--mfeSearchIcon">
          {searchToasterButton}
        </DomNodePortal>
      )}
      <SimilarEntryPoint />
      {newSearchExpierence ? (
        <>
          <FakeSearchInputField
            buttonLabel={autoSuggestDesktopPlaceholder.value}
            handleSearchIconClick={handleSearchIconClick}
          />
          {!mobileMFENavEnabled && (
          <DomNodePortal targetNodeSelector=".fake-search-input-field-portal">
            <div className={inputStyle.mobileInputContainer}>
              <FakeSearchInputField
                buttonLabel={autoSuggestDesktopPlaceholder.value}
                handleSearchIconClick={handleSearchIconClick}
              />
            </div>
          </DomNodePortal>
          )}
        </>
      ) : searchToasterButton}
      <Toaster
        closeButtonLabel={closeMenu?.value}
        direction={newSearchExpierence ? 'from-top' : 'from-right'}
        heading={toasterHeading}
        id="search-toaster"
        isFullscreen={newSearchExpierence}
        isOpen={isSearchToasterOpen}
        isSticky={newSearchExpierence}
        onClose={handleToasterClose}
        onOpen={handleToasterOpen}
      >
        {searchBox}
        { searchFlyoutRecs ? (showAlgonomyContainer
          && (
            <BreakpointProvider>
              <Recommendations
                clientId="search-flyout-1"
                handleOnProductClick={handleOnProductClick}
                mode="grid"
                placementIds={`${PLACEMENT_IDS.mobile.searchFlyout}|${PLACEMENT_IDS.desktop.searchFlyout}`}
                styles={`${style.recsWrapper}`}
              />
            </BreakpointProvider>
          )
        )
          : (
            <RecommendationSlider
              placementId="search_page.m_flyout_initial1|search_page.flyout_initial1"
              styles={`${style.recsWrapper} ${showAlgonomyContainer ? '' : style.hide}`}
            />
          )}
      </Toaster>
    </>
  );
};

SearchWrapper.propTypes = {
  brand: PropTypes.string,
  searchUrl: PropTypes.string,
};

export default SearchWrapper;
