import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import Icon from '../Common/Icon/Icon';
import { ERROR_MESSAGE, LoadingButton } from '../Messages/Messages';
import { MOBILE_SIGN_IN_BUTTON_QUERY } from './operations';

const propTypes = { onClick: PropTypes.func, isLoggedIn: PropTypes.bool.isRequired };
const defaultProps = { onClick: () => {} };

export default function MobileSignInButton({ onClick, isLoggedIn }) {
  const { loading, error, data } = useQuery(MOBILE_SIGN_IN_BUTTON_QUERY);

  if (loading) return <LoadingButton />;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;
  const { signInOrJoinText, viewAccountText } = textFor;
  const labelText = isLoggedIn ? viewAccountText : signInOrJoinText;

  return (
    <div className="mobile-sign-in-button-container" data-testid="mobile-sign-in-button-container">
      <Button
        labelText={labelText}
        variant="borderless"
        onClick={onClick}
      >
        <Icon icon="account-circle" />
      </Button>
    </div>
  );
}

MobileSignInButton.propTypes = propTypes;
MobileSignInButton.defaultProps = defaultProps;
