import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import StoreDetailsContext from '../../context/StoreDetailsContext';
import SearchGridHeader from '../ProductGridHeader/SearchGridHeader';
import SearchGrid from '../SearchGridNew/SearchGrid';
import useFeatureFlags from '../SearchPage/hooks/useFeatureFlags';
import style from './SearchMainContent.module.scss';

const SearchGridWrapper = ({
  elasticDepartmentData = null,
  facets = [],
  handleCheckBoxChange,
  handleSortChange,
  handleStoreFilter,
  onClearAllBtnClick,
  onClearFacetTag,
  onDepartmentChange,
  onPaginationButtonClick,
  parameters = {},
  searchGridData = {},
  sortData = {},
  resultsCount = 0,
  storeDetails = { storeName: '', storeId: '' },
}) => {
  const isFacetSelected = parameters.facet?.length > 0;
  const isClearAllButtonEnabled = isFacetSelected
    || !!parameters.filter;

  const {
    departmentSuggestionsFlag,
  } = useFeatureFlags();
  const showAdditionalControls = resultsCount !== 0 || !departmentSuggestionsFlag;

  const { brand } = useContext(StoreDetailsContext);

  return (
    <>
      <div className={style.gridHeaderPlaceholder}>
        {showAdditionalControls && (
          <SearchGridHeader
            brand={brand}
            departmentId={parameters.departmentId || searchGridData.departmentId}
            elasticDepartmentData={elasticDepartmentData}
            facet={parameters.facet}
            facetData={facets}
            filter={parameters.filter}
            handleStoreFilter={handleStoreFilter}
            isClearAllButtonEnabled={isClearAllButtonEnabled}
            isFacetSelected={isFacetSelected}
            onCheckBoxChange={handleCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onDepartmentChange={onDepartmentChange}
            onFacetTagClick={onClearFacetTag}
            onSortChange={handleSortChange}
            resultsCount={resultsCount}
            searchTerm={parameters.searchTerm}
            selectedSort={parameters.sort
              || sortData?.defaultSortOption || ''}
            sortData={sortData}
            storeDetails={storeDetails}
          />
        )}
      </div>
      <SearchGrid
        departmentId={parameters.departmentId}
        departmentSuggestionsFlag={departmentSuggestionsFlag}
        elasticDepartmentData={elasticDepartmentData}
        onDepartmentChange={onDepartmentChange}
        onPaginationButtonClick={onPaginationButtonClick}
        rows={parameters.rows}
        searchGridData={searchGridData}
        searchTerm={parameters.searchTerm}
        start={parameters.start}
      />
    </>
  );
};

export default SearchGridWrapper;

SearchGridWrapper.propTypes = {
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  facets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  handleStoreFilter: PropTypes.func.isRequired,
  onClearAllBtnClick: PropTypes.func.isRequired,
  onClearFacetTag: PropTypes.func.isRequired,
  onDepartmentChange: PropTypes.func.isRequired,
  onPaginationButtonClick: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    searchTerm: PropTypes.string,
    countryFulfillmentStore: PropTypes.string,
    departmentId: PropTypes.string,
    facet: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.string,
    rows: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
    initialSearchTerm: PropTypes.string,
  }),
  resultsCount: PropTypes.number,
  searchGridData: PropTypes.shape({
    loading: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.number,
      }),
      productImage: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      productPageUrl: PropTypes.string,
    })),
    pagination: PropTypes.shape({
      currentPage: PropTypes.number,
      totalPages: PropTypes.number,
    }),
  }),
  sortData: PropTypes.shape({
    defaultSortOption: PropTypes.string,
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};
