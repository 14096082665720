import { RecommendationGridSliders, RecommendationSliders } from '@xp-utilities/web';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import RecommendationsContextProvider from '../../context/RecommendationsContextProvider';
import BreakpointContext from '../../context/breakpoint';
import useIsSsr from '../../hooks/useIsSsr';
import parsePlacementIds from '../../tools/parsePlacementIds';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';

const Recommendations = ({
  clientId, handleOnProductClick, placementIds, mode = 'slider', styles = '',
}) => {
  const breakpoints = useContext(BreakpointContext);
  const placements = parsePlacementIds(placementIds, breakpoints);
  const recommendationsProps = {
    clientId,
    placements,
  };

  const isSsr = useIsSsr();

  // To reduce the spike in algonomy API calls with missing placementIds
  if (isSsr) return null;

  return (
    <RecommendationsContextProvider id={recommendationsProps.clientId}>
      <div
        className={`${recsContainerStyles.recommendationsContainer} ${styles}`}
        data-recommendations-client={recommendationsProps.clientId}
        data-testid={recommendationsProps.clientId}
        id={recommendationsProps.clientId}
      >
        {mode === 'grid' ? (
          <RecommendationGridSliders
            onProductClick={handleOnProductClick}
            placements={recommendationsProps.placements}
          />
        )
          : (
            <RecommendationSliders
              onProductClick={handleOnProductClick}
              placements={recommendationsProps.placements}
            />
          )}
      </div>
    </RecommendationsContextProvider>
  );
};

export default Recommendations;

Recommendations.propTypes = {
  clientId: PropTypes.string.isRequired,
  styles: PropTypes.string,
  handleOnProductClick: PropTypes.func.isRequired,
  placementIds: PropTypes.string.isRequired,
  mode: PropTypes.string,
};
