import React from 'react';
import { useQuery } from '@apollo/client';
import SignInForm from './SignInForm';
import JoinForm from './JoinForm';
import { ERROR_MESSAGE } from '../Messages/Messages';
import RememberedFormContainer from './RememberedFormContainer';
import {
  useAuthenticationForm,
  useAuthenticationFormDispatch,
  AUTH_FORMS,
} from '../../context/AuthenticationFormContext/AuthenticationFormContext';
import { AUTHENTICATION_FORM_QUERY } from './operations';
import {
  AuthenticationFormDefaultProps,
  AuthenticationFormPropTypes,
  AuthenticationFormWrapperPropTypes,
} from './props';

function AuthenticaionFormWrapper({ children }) {
  return (
    <div className="authentication-form-outer-wrapper">
      <div className="authentication-form-inner-wrapper">
        {children}
      </div>
    </div>
  );
}

AuthenticaionFormWrapper.propTypes = AuthenticationFormWrapperPropTypes;

/*
  Note:

  Authentication modal sign in and join form queries aren't pulled
  from persistent cache. Issue in PROD, observed during PRO-1440

  Both queries are persisted queries and are static data
*/

export default function AuthenticationForm({ onSuccessfulSubmission, onFailedSubmission }) {
  const context = useAuthenticationForm();
  const dispatch = useAuthenticationFormDispatch();

  const {
    data, loading, error, refetch,
  } = useQuery(
    AUTHENTICATION_FORM_QUERY,
    {
      ssr: false,
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
    },
  );

  const handleOnSignInClick = () => dispatch({ type: 'showForm', form: AUTH_FORMS.SIGN_IN });
  const handleOnJoinClick = () => dispatch({ type: 'showForm', form: AUTH_FORMS.JOIN });

  const handleIsExistingUser = (userData) => {
    dispatch({ type: 'existingUser', user: userData });
    dispatch({ type: 'showForm', form: AUTH_FORMS.SIGN_IN });
  };

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { userState, config } = data;
  const { isRemembered } = userState;
  const { userAccountViewUrl } = config;

  const defaultOnSuccessfulSubmission = () => { window.location.assign(userAccountViewUrl); };
  const onSuccess = () => {
    const { redirect, form } = context;
    onSuccessfulSubmission(); // passed from AuthenticationModal
    if (redirect) window.location.assign(context.redirect);
    else if (form === AUTH_FORMS.SIGN_IN && !redirect) { // Regular sign in
      setTimeout(() => window.location.reload(), 300);
    }
  };
  const handleOnSuccessfulSubmission = onSuccessfulSubmission
    ? onSuccess
    : defaultOnSuccessfulSubmission;

  if (isRemembered) {
    return (
      <AuthenticaionFormWrapper>
        <RememberedFormContainer
          onFailedSubmission={onFailedSubmission}
          onLogoutCompleted={refetch}
          onSuccessfulSubmission={handleOnSuccessfulSubmission}
        />
      </AuthenticaionFormWrapper>
    );
  }

  const { user = {}, form } = context;

  if (form !== AUTH_FORMS.SIGN_IN) {
    return (
      <AuthenticaionFormWrapper>
        <JoinForm
          onFailedSubmission={onFailedSubmission}
          onIsExistingUser={handleIsExistingUser}
          onSignInClick={handleOnSignInClick}
          onSuccessfulSubmission={handleOnSuccessfulSubmission}
        />
      </AuthenticaionFormWrapper>

    );
  }

  return (
    <AuthenticaionFormWrapper>
      <SignInForm
        email={user?.email}
        keepMeSignedIn={user?.keepMeSignedIn}
        onFailedSubmission={onFailedSubmission}
        onJoinClick={handleOnJoinClick}
        onSuccessfulSubmission={handleOnSuccessfulSubmission}
        password={user?.password}
      />
    </AuthenticaionFormWrapper>
  );
}

AuthenticationForm.propTypes = AuthenticationFormPropTypes;
AuthenticationForm.defaultProps = AuthenticationFormDefaultProps;
