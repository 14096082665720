import {
  gql,
} from '@apollo/client';

export const GET_FEATURE_FLAG_QUERY = gql`
query FetchFeatureFlag($key: String!, $defaultValue: Boolean) {
  featureFlag {
    isOn(key: $key, defaultValue: $defaultValue)
  }
}`;

export const GET_FEATURE_FLAGS_QUERY = gql`
query FetchFeatureFlags($keysWithDefaultValue: [FeatureFlagInput!]!) {
  featureFlag {
    featureFlags(keysWithDefaultValue: $keysWithDefaultValue) {
      flags {
        key
        value
      }
    }
  }
}`;
