import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'anf-core-react';
import AuthenticationForm from './AuthenticationForm';
import { AuthenticationModalContext } from '../../context/ModalContext';
import { useEventListener, useInstance } from '../../hooks';
import {
  useAuthenticationForm,
  AUTH_FORMS,
  useAuthenticationFormDispatch,
} from '../../context/AuthenticationFormContext/AuthenticationFormContext';

export const AUTH_MODAL_ID = 'authentication-modal';

const propTypes = { isLoyaltyEnabled: PropTypes.bool };
const defaultProps = { isLoyaltyEnabled: true };

export default function AuthenticationModal({ isLoyaltyEnabled }) {
  const modalContext = useContext(AuthenticationModalContext);
  const { isModalOpen, closeModal } = modalContext;
  const dispatch = useAuthenticationFormDispatch();
  const { form } = useAuthenticationForm();
  /*
    Note: Adding an instance to ensure that only a single instance of the auth modal is
    opened at a time. Issue arose when soft auth is activated. The `event.target`
    initializes the instance value and then blocks subsequent open calls.
  */
  const [getInstance, setInstance] = useInstance(AUTH_MODAL_ID);

  const openSuccessModal = () => document.dispatchEvent(new CustomEvent(`${AUTH_MODAL_ID}:success`));

  const handleOnOpenModal = () => {
    const { isModalOpen: isOpen, openModal: openModalInstance } = getInstance();
    if (!isOpen) openModalInstance();
  };

  const handleOnOpenEvent = (event) => {
    const { isSignIn = true } = event.detail;

    if (!getInstance()) setInstance(modalContext);
    handleOnOpenModal();

    if (!isSignIn) dispatch({ type: 'showForm', form: AUTH_FORMS.JOIN });
    else dispatch({ type: 'showForm', form: AUTH_FORMS.SIGN_IN });
  };

  const handleOnClose = () => {
    closeModal();
    setInstance(null);
  };

  const handleOnSuccessfulSubmission = () => {
    handleOnClose();
    if (form === AUTH_FORMS.JOIN && isLoyaltyEnabled) openSuccessModal();
    else if (form === AUTH_FORMS.JOIN) setTimeout(() => window.location.reload(), 300);
  };

  useEventListener(`${AUTH_MODAL_ID}:open`, handleOnOpenEvent);

  return (
    <Modal
      id={AUTH_MODAL_ID}
      isOpen={isModalOpen}
      onClose={handleOnClose}
      variant="headerless"
    >
      <div className="scope-1892">
        <AuthenticationForm onSuccessfulSubmission={handleOnSuccessfulSubmission} />
      </div>
    </Modal>
  );
}

AuthenticationModal.propTypes = propTypes;
AuthenticationModal.defaultProps = defaultProps;
