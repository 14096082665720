import React, { useState, useCallback } from 'react';
import { TransactionSummary, TransactionRow, Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import Disclosure from '../../ShoppingBag/Disclosure/Disclosure';
import VatModal from '../VatModal/VatModal';
import MaskedGiftCard from '../GiftCards/MaskedGiftCard';

export default function OrderTotals({
  totalItemsInBag,
  cartTotalAmount,
  cartPayableAmount,
  promoAmountFmt,
  shippingHandlingChargeFmt,
  totalGiftBoxes,
  totalGiftBoxesAmount,
  estimatedTaxAmountFmt,
  charityRoundUpFmt,
  tmntData,
  includesConsumptionTax,
  includesGst,
  includesVat,
  showDisclosure,
  shippingModeType,
  giftCards,
  isVatIncluded,
  vatDetails,
  displayFreeShippingText,
  displayGiftReceiptText,
}) {
  const merchandiseTotalTextTMNTValue = tmntData?.merchandiseTotalTextTMNTValue?.value;
  const itemsTMNTValue = tmntData?.itemsLabel?.value;
  const promoDiscountsTMNTValue = tmntData?.totalDiscountTextTMNTValue?.value
    ? tmntData?.totalDiscountTextTMNTValue?.value : tmntData?.promoDiscountsLabel?.value;
  const giftBoxesTMNTValue = tmntData?.giftBoxesLabelTMNTValue?.value;
  const estimatedTaxTMNTValue = tmntData?.estimatedTaxLabelTMNTValue?.value
    || tmntData?.taxLabelTMNTValue?.value;
  const charityTMNTValue = tmntData?.charityLabelTMNTValue?.value;
  const orderTotalsText = tmntData?.subtotalTMNTValue?.value ? tmntData?.subtotalTMNTValue?.value
    : tmntData?.orderTotal?.value;
  const shippingHandlingTMNTValue = tmntData?.shippingHandlingTextTMNTValue?.value || '';
  const popinsShippingAndHandlingTMNTValue = tmntData?.popinsShippingAndHandling?.value || '';
  const pickupPointShippingAndHandlingTMNTValue = tmntData?.pickupPointShippingAndHandling?.value || '';
  let shippingHandlingText = shippingHandlingTMNTValue;

  // TODO: where is shippingModeType coming from?
  // I added optional chain operator below because of a hard undefined error - Jake S.
  if (shippingModeType?.toLowerCase() === 'popins') {
    shippingHandlingText = popinsShippingAndHandlingTMNTValue;
  } else if (shippingModeType?.toLowerCase() === 'pudos') {
    shippingHandlingText = pickupPointShippingAndHandlingTMNTValue;
  }
  const orderTotalIncludesConsumpTaxText = tmntData?.orderTotalIncludesConsumpTaxTMNTValue?.value;
  const orderTotalIncludesGstText = tmntData?.orderTotalIncludesGstTMNTValue?.value;
  const orderTotalIncludesVatText = tmntData?.orderTotalIncludesVatTMNTValue?.value;
  const freeShippingHandlingText = tmntData?.freeShippingHandling?.value || '';
  const giftCardText = tmntData?.giftCardLabelTMNTValue?.value || '';
  const vatTMNTValue = tmntData?.vatTMNTValue?.value || '';
  const detailsTMNTValue = tmntData?.detailsTMNTValue?.value || '';
  const giftReceipText = tmntData?.giftReceipText?.value;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const transactionSection = () => (
    <TransactionSummary
      itemColumnLabel="Item"
      itemSummaryLabel={orderTotalsText}
      priceColumnLabel="Price"
      priceSummaryLabel={decodeHTMLEntities(cartPayableAmount)}
      className="transaction-summary"
    >
      {totalItemsInBag ? (
        <TransactionRow
          itemLabel={`${itemsTMNTValue} (${totalItemsInBag})`}
          priceLabel={decodeHTMLEntities(cartTotalAmount)}
        />
      ) : ''}
      {((promoAmountFmt || totalGiftBoxes) && cartTotalAmount && merchandiseTotalTextTMNTValue) && (
        <TransactionRow
          itemLabel={merchandiseTotalTextTMNTValue}
          priceLabel={decodeHTMLEntities(cartTotalAmount)}
        />
      )}
      {((promoAmountFmt)
        && (
          <TransactionRow
            isNegative
            itemLabel={promoDiscountsTMNTValue}
            priceLabel={decodeHTMLEntities(promoAmountFmt)}
          />
        )
      )}
      {shippingHandlingChargeFmt && (
        <TransactionRow
          itemLabel={shippingHandlingText}
          priceLabel={decodeHTMLEntities(shippingHandlingChargeFmt)}
        />
      )}

      {displayFreeShippingText && (
        <TransactionRow
          itemLabel={shippingHandlingText}
          priceLabel={decodeHTMLEntities(freeShippingHandlingText)}
          isNegative
        />
      )}
      {
        giftCards?.map((giftcard) => (
          <TransactionRow
            isNegative
            key={giftcard.id}
            itemLabel={(
              <>
                {giftCardText}
                {' '}
                <MaskedGiftCard giftcard={giftcard} />
              </>
            )}
            priceLabel={`-${decodeHTMLEntities(giftcard.amountFmt)}`}
          />
        ))
      }
      {totalGiftBoxesAmount && (
        <TransactionRow
          itemLabel={`${giftBoxesTMNTValue} (${totalGiftBoxes})`}
          priceLabel={decodeHTMLEntities(totalGiftBoxesAmount)}
        />
      )}
      {!!displayGiftReceiptText && (
        <TransactionRow
          itemLabel={`${giftReceipText}`}
          priceLabel={decodeHTMLEntities(freeShippingHandlingText)}
          isNegative
        />
      )}

      {estimatedTaxAmountFmt && (
        <TransactionRow
          itemLabel={estimatedTaxTMNTValue}
          priceLabel={decodeHTMLEntities(estimatedTaxAmountFmt)}
        />
      )}
      {charityRoundUpFmt && (
        <TransactionRow
          itemLabel={charityTMNTValue}
          priceLabel={decodeHTMLEntities(charityRoundUpFmt)}
        />
      )}
      {isVatIncluded && (
        <TransactionRow
          itemLabel={(
            <>
              {vatTMNTValue}
              {' '}
              <Button
                type="button"
                variant="borderless"
                data-property="GLB_SUBTOTALINCLVAT"
                onClick={handleOpenModal}
                classList="vat-details-button"
              >
                {detailsTMNTValue}
              </Button>
              <VatModal
                openModal={openModal}
                closeModal={handleCloseModal}
                vatDetails={vatDetails}
              />
            </>
          )}
          priceLabel=""
        />
      )}
    </TransactionSummary>
  );

  return (
    <div className="order-total-content">
      <div data-testid="order-summary" data-module-state="readonly" data-module-loading="false">
        {transactionSection()}
        {cartPayableAmount && (
          <div className="tax-subtext">
            {includesConsumptionTax && (
              <span data-property="GLB_ORDER_TOTAL_INCLUDES_CONSUMP_TAX">
                {orderTotalIncludesConsumpTaxText}
              </span>
            )}
            {includesGst && (
              <span data-property="GLB_ORDER_TOTAL_INCLUDES_GST">
                {orderTotalIncludesGstText}
              </span>
            )}
            {includesVat && (
              <span data-property="GLB_ORDER_TOTAL_INCLUDES_VAT">
                {orderTotalIncludesVatText}
              </span>
            )}
          </div>
        )}
        {/* disclosure section for cn & am sites start */}
        <div className="disclosure-info">
          {showDisclosure && <Disclosure tmntData={tmntData} />}
        </div>
        {/* disclosure section for cn & am sites end */}
      </div>
    </div>
  );
}

OrderTotals.defaultProps = {
  totalItemsInBag: '',
  cartTotalAmount: '',
  cartPayableAmount: '',
  promoAmountFmt: '',
  shippingHandlingChargeFmt: '',
  totalGiftBoxes: '',
  totalGiftBoxesAmount: '',
  estimatedTaxAmountFmt: '',
  charityRoundUpFmt: '',
  tmntData: {},
  includesConsumptionTax: false,
  includesGst: false,
  includesVat: false,
  showDisclosure: false,
  shippingModeType: '',
  giftCards: [],
  isVatIncluded: false,
  vatDetails: null,
  displayFreeShippingText: false,
  displayGiftReceiptText: false,
};

OrderTotals.propTypes = {
  totalItemsInBag: PropTypes.string,
  promoAmountFmt: PropTypes.string,
  cartPayableAmount: PropTypes.string,
  shippingHandlingChargeFmt: PropTypes.string,
  cartTotalAmount: PropTypes.string,
  totalGiftBoxes: PropTypes.string,
  totalGiftBoxesAmount: PropTypes.string,
  estimatedTaxAmountFmt: PropTypes.string,
  charityRoundUpFmt: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  includesConsumptionTax: PropTypes.bool,
  includesGst: PropTypes.bool,
  includesVat: PropTypes.bool,
  showDisclosure: PropTypes.bool,
  isVatIncluded: PropTypes.bool,
  shippingModeType: PropTypes.string,
  giftCards: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  vatDetails: PropTypes.instanceOf(Object),
  displayFreeShippingText: PropTypes.bool,
  displayGiftReceiptText: PropTypes.bool,
};
