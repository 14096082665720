import React, {
  useContext,
} from 'react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import OrderTotals from '../../Common/OrderTotals/OrderTotals';
import BagList from '../../Common/BagList/BagList';
import Tmnt from '../../Tmnt/Tmnt';

export default function RightRail() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);

  const getSelectedShipInfo = (shippingSpeed) => {
    const selectedShipInfo = shippingSpeed?.filter((d) => d?.isSelected);
    return selectedShipInfo?.length ? selectedShipInfo[0] : {};
  };

  return (
    <div className="checkout-mfe-web-service" data-testid="right-rail-content">
      {orderConfirmationData?.textFor && (

      <div id="right-rail-shopping-bag">
        <h2 className="header-bag-text">
          <Tmnt tmnt={orderConfirmationData?.textFor?.yourOrder} />
        </h2>
        <div data-testid="baglist" className="checkout-baglist">
          <BagList
            variant="rightRail"
            bagItems={orderConfirmationData?.bagItems?.items}
            tmntData={orderConfirmationData?.textFor}
            shippingInfo={getSelectedShipInfo(orderConfirmationData?.shippingSpeed)}
          />
        </div>
        <h3 className="small-screen-selector"><Tmnt tmnt={orderConfirmationData?.textFor?.paymentLabel} /></h3>
        <section className="order-summary summary-tax-confirmation">
          <OrderTotals
            cartTotalAmount={orderConfirmationData?.orderTotals?.cartTotalAmount}
            tmntData={orderConfirmationData?.textFor}
            promoAmountFmt={orderConfirmationData?.orderTotals?.promoAmountFmt}
            cartPayableAmount={orderConfirmationData?.orderTotals?.grandTotalFmt}
            includesConsumptionTax={orderConfirmationData?.orderTotals?.includesConsumptionTax}
            charityRoundUpFmt={orderConfirmationData?.orderTotals?.charityTotalFmt}
            includesGst={orderConfirmationData?.orderTotals?.includesGst}
            includesVat={orderConfirmationData?.orderTotals?.includesVat}
            showDisclosure={!!orderConfirmationData?.config?.showDisclosure}
            isVatIncluded={orderConfirmationData?.config?.isVatIncluded}
            totalItemsInBag={orderConfirmationData?.orderTotals?.totalItemsInBag}
            shippingHandlingChargeFmt={orderConfirmationData
              ?.orderTotals?.shippingHandlingChargeFmt}
            estimatedTaxAmountFmt={orderConfirmationData?.orderTotals?.estimatedTaxAmountFmt}
            isCheckoutPage
            shippingModeType={orderConfirmationData?.shipMethod?.shipModeType}
            totalGiftBoxesAmount={orderConfirmationData?.orderTotals?.totalGiftBoxesAmount}
            totalGiftBoxes={orderConfirmationData?.orderTotals?.totalGiftBoxes}
            giftCards={orderConfirmationData?.giftCards?.appliedGiftCards}
            displayFreeShippingText={orderConfirmationData?.orderTotals?.displayFreeShippingText}
            displayGiftReceiptText={orderConfirmationData?.orderTotals?.displayGiftReceiptText}
            vatDetails={orderConfirmationData?.vatDetails}
          />
        </section>
      </div>
      )}
    </div>
  );
}
