import React from 'react';
import hydrate from '../lib/hydrate';
import { AuthenticationModal } from '../lib/components';
import { AuthenticationModalContext, ModalContextProvider } from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

function AuthenticationModalFrontend() {
  return (
    <AuthenticationFormContextProvider>
      <ModalContextProvider context={AuthenticationModalContext}>
        <AuthenticationModal />
      </ModalContextProvider>
    </AuthenticationFormContextProvider>
  );
}

hydrate({
  frontend: 'AuthenticationModalFrontend',
  component: AuthenticationModalFrontend,
  persist: true,
});
