import React, { useMemo } from 'react';
import SwitchTestProvider from '../../SwitchTestProvider/SwitchTestProvider';
import MiniBagWithIcon from '../MiniBagWithIcon/MiniBagWithIcon';
import MiniBagIconStatic from '../MiniBagIconStatic/MiniBagIconStatic';
import $window from '../../../tools/window';
import { MINI_BAG_SWITCHES } from '../../../tools/constants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import MiniBagWithIconV2 from '../MiniBagWithIconV2/MiniBagWithIconV2';

export default function MiniBagWrapper() {
  const isOCNPage = useMemo(() => $window.location?.href?.includes('/OrderConfirmationDisplayView'), []);
  const { isFeatureFlagOn: enableMinibagV2, loading } = useFeatureFlag('chk-use-mfe-minibag-with-icon-v2', false, { fetchPolicy: 'cache-first' });

  if (loading) {
    return null;
  }

  if (enableMinibagV2) {
    return (
      <SwitchTestProvider keys={MINI_BAG_SWITCHES}>
        <MiniBagWithIconV2 />
      </SwitchTestProvider>
    );
  }

  return (
    <SwitchTestProvider keys={MINI_BAG_SWITCHES}>
      {isOCNPage ? <MiniBagIconStatic /> : <MiniBagWithIcon />}
    </SwitchTestProvider>
  );
}
