import React, {
  useState, useEffect, useContext, useCallback, useMemo,
} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import PudosModal from './PudosModal';
import { getPudosResultsQuery, pudosModalTextQuery } from '../../../gql/pudosOperations.gql';
import useGeocoder from '../../../hooks/useGeocoder';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import $window from '../../../tools/window';

const id = 'pudos-modal';
export default function PudosModalContainer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isInputSearching, setIsInputSearching] = useState(false);
  const [isNearMeSearching, setIsNearMeSearching] = useState(false);
  const [areStoresFound, setAreStoresFound] = useState(false);
  const [storeCardBrand, setStoreCardBrand] = useState(undefined);
  const [pudosResults, setPudosResults] = useState(undefined);
  const [pudosModalText, setPudosModalText] = useState(undefined);
  const [defaultSearchValue, setDefaultSearchValue] = useState(undefined);
  const [defaultMapCenter, setDefaultMapCenter] = useState(undefined);
  const [isGeolocationBlocked, setIsGeolocationBlocked] = useState(false);
  const { checkoutPageState, selectedStore, setSelectedStore } = useContext(CheckoutPageContext);
  const geocoder = useGeocoder({ apiKey: checkoutPageState.googleApiKey });

  const {
    loading: textLoading,
    error: textError,
  } = useQuery(pudosModalTextQuery, {
    onCompleted: ({ textFor, brand }) => {
      setPudosModalText(textFor);
      setStoreCardBrand(brand);
    },
    onError: () => {
      setIsErrorState(true);
    },
  });

  const [getPudosResults] = useLazyQuery(getPudosResultsQuery, {
    onCompleted: ({ pudos }) => {
      if (pudos?.remoteLocations) {
        setPudosResults(pudos.remoteLocations);
        setIsInputSearching(false);
        setIsNearMeSearching(false);
        if (pudos?.remoteLocations?.pudosLocations?.length > 0) {
          setAreStoresFound(true);
        }
      }
    },
    onError: () => {
      setIsInputSearching(false);
      setIsNearMeSearching(false);
      setIsErrorState(true);
    },
  });

  const selectedPudosLocationId = useMemo(() => {
    if (checkoutPageState?.shippingAddress?.addressType === 'CP') {
      return checkoutPageState.shippingAddress.collectionPointID;
    }
    return undefined;
  }, [checkoutPageState.shippingAddress]);

  const searchForStores = useCallback(async (value) => {
    // before searching clear the values and reset the error state
    setAreStoresFound(false);
    setPudosResults(null);
    setIsErrorState(false);
    setIsGeolocationBlocked(false);

    let geolocation;
    const locationsControlParameters = {
      distance: {
        units: 'MI',
        value: 50,
      },
    };
    try {
      if (!value) {
        geolocation = await geocoder.findMe();
      } else {
        geolocation = await geocoder.getAddressGeolocation(value);
      }

      setDefaultMapCenter({ latitude: geolocation.latlng[0], longitude: geolocation.latlng[1] });
      getPudosResults({
        variables: {
          locationsControlParameters,
          location: {
            city: geolocation.postalTown,
            country: geolocation.country,
            countryCode: geolocation.countryAbbr,
            latitude: geolocation.latlng[0],
            longitude: geolocation.latlng[1],
            postalCode: geolocation.postalCode,
          },
        },
      });
    } catch (error) {
      setIsInputSearching(false);
      setIsNearMeSearching(false);
      if (error instanceof $window.GeolocationPositionError || error.message === 'geoposition-error') {
        setIsGeolocationBlocked(true);
      } else {
        setIsErrorState(true);
      }
    }
  }, [getPudosResults, geocoder]);

  const handleInputSearch = useCallback((value) => {
    setIsInputSearching(true);
    searchForStores(value);
  }, [searchForStores]);

  const handleNearMeSearch = useCallback(() => {
    setIsNearMeSearching(true);
    searchForStores();
  }, [searchForStores]);

  const handleOpenModal = useCallback((event) => {
    // near me search takes a higher priority
    if (event?.detail?.nearme) {
      // doing a near me search we don't want to reset the default search value
      setIsNearMeSearching(true);
      searchForStores('');
    } else if (event?.detail?.value) {
      setIsInputSearching(true);
      setDefaultSearchValue(event.detail.value);
      searchForStores(event.detail.value);
    }
    setIsModalOpen(true);
  }, [searchForStores]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    // clear it out so that the next time the modal is opened it will search for the stores using
    // the preferred store if nothing was selected
    setDefaultSearchValue(null);
    if (selectedStore) {
      // respond back with the same store
      $window.dispatchEvent(new CustomEvent('pudos:mfeStoreSelected', {
        detail: {
          selectedStore,
        },
      }));
    }
  }, [selectedStore]);

  const handleSetSelectedStore = useCallback((store) => {
    setSelectedStore(store);
    setIsModalOpen(false);
    $window.dispatchEvent(new CustomEvent('pudos:mfeStoreSelected', {
      detail: {
        selectedStore: store,
      },
    }));
  }, [setSelectedStore]);

  useEffect(() => {
    $window.addEventListener(`${id}:open`, handleOpenModal);
    return () => {
      $window.removeEventListener(`${id}:open`, handleOpenModal);
    };
  }, [handleOpenModal]);

  useEffect(() => {
    $window.addEventListener(`${id}:close`, handleCloseModal);
    return () => {
      $window.removeEventListener(`${id}:close`, handleCloseModal);
    };
  }, [handleCloseModal]);

  if (textLoading || textError) return null;

  return (
    <PudosModal
      defaultSearchValue={defaultSearchValue}
      defaultMapCenter={defaultMapCenter}
      isErrorState={isErrorState}
      isGeolocationBlocked={isGeolocationBlocked}
      isOpen={isModalOpen}
      isInputSearching={isInputSearching}
      isNearMeSearching={isNearMeSearching}
      areStoresFound={areStoresFound}
      onClose={handleCloseModal}
      onInputSearch={handleInputSearch}
      onNearMeSearch={handleNearMeSearch}
      onSetSelectedStore={handleSetSelectedStore}
      pudosResults={pudosResults}
      selectedStore={selectedStore}
      selectedPudosId={selectedPudosLocationId}
      text={pudosModalText}
      brand={storeCardBrand}
      googleApiKey={checkoutPageState.googleApiKey}
    />
  );
}
