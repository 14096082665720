import gql from 'graphql-tag';
import { textPairFragment } from '../../../query/fragments';

export const LOGGED_OUT_LEFT_NAV_QUERY = gql`
  query LOGGED_OUT_LEFT_NAV_QUERY {
    textFor {
      guestNavText: pair(pairKey: "guestNavText") { ...textPair }
      helpText: pair(pairKey: "helpText") { ...textPair }
      signInText: pair(pairKey: "signIn") { ...textPair }
    }
    config {
      enableMFEAuthenticationModal: bool(name: "enableMFEAuthenticationModal")
      helpPageUrl: string(name: "helpPageUrl")
    }
  }

  ${textPairFragment}
`;

export const LOGGED_IN_LEFT_NAV_QUERY = gql`
  query LOGGED_IN_LEFT_NAV_QUERY {
    textFor {
      welcomeMessageText: nonCachedPair(pairKey: "welcomeMessage") { ...textPair }
      memberSinceText: nonCachedPair(pairKey: "loyaltyMemberSinceMessage") { ...textPair }
      navLinks: listField(fieldKey: "leftNavLinks") { ...linkListValues }
      loyaltyTierImageAltText: pair(pairKey: "loyaltyTierLogoLabel") {...textPair}
    }
    config {
      loyaltyTierImageSrc: nonCachedString(name: "loyaltyTierImageUrl")
    }
  }

  fragment linkListValues on FieldOptionList {
    label { ...textPair }
    options {
      url: optionValue
      text {...textPair}
    }
  }

  ${textPairFragment}
`;
