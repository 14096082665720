import React, { useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  FieldGroup,
  Button,
  Icon,
  ErrorMessage,
  LegalText,
  Badge,
  InputField,
  DropDown,
} from 'anf-core-react';
import { useController, useForm } from 'react-hook-form';
import { MY_INFO_FORM_QUERY, UPDATE_MY_INFO } from './operations';
import ControlledInputField from '../Common/ControlledInputField/ControlledInputField';
import ControlledBirthdayFields from '../Common/ControlledBirthdayFields/ControlledBirthdayFields';
import EmailInputField from '../Common/EmailInputField/EmailInputField';
import PhoneNumberField from '../Common/PhoneNumber/PhoneNumberField';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import ChangePasswordModalContainer, { id } from '../ChangePasswordBlock/ChangePasswordModalContainer';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import useButtonState from '../Common/ButtonState/useButtonState';
import { TmntText, TmntHtml } from '../Common/Text';
import * as FormStructure from '../FormStructure';
import { generateRandomString } from '../../tools/random';
import { Row, Column } from '../Common/Grid';
import { useLegalLinkEvents } from '../../hooks';
import LegalModalButton from '../Common/LegalModalButton/LegalModalButton';
import { ModalContextProvider } from '../../context/ModalContext';
import LegalModal from '../Common/LegalModalBlock/LegalModal';

const NAMES = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  birthMonth: 'birth-month',
  birthDay: 'birth-day',
  birthYear: 'birth-year',
  email: 'email',
  password: 'current-password',
  phoneNumber: 'phoneNumber',
};

// needed in order to translate values from shopping preferences options
const GENDER_OPTION_MAP = {
  male: 'M',
  female: 'F',
  na: 'N',
};

export default function MyInfoBlock() {
  const { loading: queryLoading, error: queryError, data = {} } = useQuery(MY_INFO_FORM_QUERY);
  const userInfoCache = data?.userInfo;
  const [renderButton, registerPromise] = useButtonState();
  const [showBadge, setShowBadge] = useState(false);

  const legalLinkRef = useRef(null);
  const [legalTmntKey, setLegalTmntKey] = useState(null);

  useLegalLinkEvents(legalLinkRef, (value) => {
    setLegalTmntKey(value);
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    getValues,
    reset,
    formState: {
      isDirty,
      errors,
      isSubmitSuccessful,
      isValid,
    },
  } = useForm({
    defaultValues: {
      [NAMES.firstName]: userInfoCache?.firstName,
      [NAMES.lastName]: userInfoCache?.lastName,
      [NAMES.gender]: userInfoCache?.preferences?.gender,
      [NAMES.birthMonth]: userInfoCache?.preferences?.birthMonth || '',
      [NAMES.birthDay]: userInfoCache?.preferences?.birthDay || '',
      [NAMES.birthYear]: userInfoCache?.preferences?.birthYear,
      [NAMES.email]: userInfoCache?.email,
      [NAMES.password]: generateRandomString(8, 12),
      [NAMES.phoneNumber]: userInfoCache?.phone,
    },
  });
  const { field: passwordField } = useController({
    control,
    name: NAMES.password,
  });

  const { field: genderField } = useController({
    control,
    name: NAMES.gender,
  });

  const [updateInfoMutation] = useMutation(UPDATE_MY_INFO, {
    onCompleted: () => {
      document.dispatchEvent(new CustomEvent('accountUtilNav:render'));
      document.dispatchEvent(new CustomEvent('navHeader:init'));
      document.dispatchEvent(new CustomEvent('myInfo:updated'));
    },
  });

  if (queryError) return ERROR_MESSAGE;
  if (queryLoading) return LOADING_MESSAGE;

  const handleOpenModal = () => {
    document.dispatchEvent(new Event(`${id}:open`));
  };

  const processGenderList = ({ optionValue, text }) => {
    const value = GENDER_OPTION_MAP[optionValue] || optionValue;
    return ({ label: text?.value, value });
  };

  const renderUserInformation = (field) => {
    const {
      name,
      label,
      error,
      requiredField,
    } = field;
    const idName = `my-info-${name}`;

    switch (name) {
      case 'firstName':
        return (
          <ControlledInputField
            autoComplete="name"
            id={idName}
            key={idName}
            label={label?.value}
            name={name}
            control={control}
          >
            <ErrorMessage>
              <TmntText tmnt={error} />
            </ErrorMessage>
          </ControlledInputField>
        );
      case 'lastName':
        return (
          <ControlledInputField
            autoComplete="name"
            id={idName}
            key={idName}
            label={label?.value}
            name={name}
            control={control}
          >
            <ErrorMessage>
              <TmntText tmnt={error} />
            </ErrorMessage>
          </ControlledInputField>
        );
      case 'gender':
        return (
          <DropDown
            id={idName}
            key={idName}
            label={label?.value}
            name={name}
            onChange={genderField.onChange}
            options={data.textFor.shoppingPreferenceText.options.map(processGenderList)}
            value={genderField.value || ''}
          />
        );
      case 'email':
        return (
          <EmailInputField
            id={idName}
            key={idName}
            name={name}
            isRequired
            label={label?.value}
            control={control}
          >
            <ErrorMessage>
              <TmntText tmnt={error} />
            </ErrorMessage>
          </EmailInputField>
        );
      case 'password':
        return (
          <InputField
            autoComplete="off"
            id={idName}
            key={idName}
            type="password"
            label={label?.value}
            isDisabled
            name={name}
            value={passwordField.value}
            onChange={passwordField.onChange}
          >
            <Button
              theme="inverse"
              variant="secondary"
              onClick={handleOpenModal}
            >
              {data.textFor.editButton?.value}
            </Button>
            <ChangePasswordModalContainer />
          </InputField>
        );
      case 'phoneNumber':
        return (
          <PhoneNumberField
            control={control}
            id={idName}
            key={idName}
            label={label?.value}
            name={name}
            isRequired={requiredField}
          >
            <ErrorMessage>
              <TmntText tmnt={error} />
            </ErrorMessage>
          </PhoneNumberField>
        );
      default:
        return null;
    }
  };

  const handleOnSubmit = (formData = {}) => {
    clearErrors();
    setShowBadge(false);

    const variables = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      primaryPhone: formData.phoneNumber,
      preference: {
        gender: formData[NAMES.gender],
        birthMonth: formData[NAMES.birthMonth],
        birthDay: formData[NAMES.birthDay],
        birthYear: formData[NAMES.birthYear],
      },
    };
    try {
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
        const call = new Promise((resolve, reject) => {
          updateInfoMutation({ variables })
            .then(({ data: responseData }) => {
              const { updateUserInfo } = responseData;
              const { success } = updateUserInfo;
              if (!success) reject();
              else {
                resolve();
              }
            })
            .catch(() => { reject(); });
        });
        registerPromise(call);
        setShowBadge(true);
        reset(formData);
      }
    } catch { /* do nothing */ }
  };

  const renderBadge = () => (
    <div className="info-banner-success-banner">
      <Badge>
        <TmntText tmnt={data.textFor.myInfoSuccessMessage} />
      </Badge>
    </div>
  );

  return (
    <div className="my-info-block" data-testid="my-info-block-content">
      {data && data.userState.isLoggedIn ? (
        <AccountBlock
          heading={(
            <SectionHeading>
              <h2 className="h3" data-property={data.textFor.myInfoHeader?.key}>
                {data.textFor.myInfoHeader?.value}
              </h2>
            </SectionHeading>
          )}
        >
          <FormStructure.FormCell isLoading={queryLoading}>
            {showBadge && isSubmitSuccessful && renderBadge()}
          </FormStructure.FormCell>
          <Row>
            <Column md="4" xs="8">
              <form
                onSubmit={handleSubmit(handleOnSubmit)}
                name="my-info-form"
                className="my-info-form"
                noValidate
              >
                <FormStructure.FormWrapper>
                  <FormStructure.FormGroup>
                    <FormStructure.FormCell isLoading={queryLoading}>
                      {data.textFor.myInfoForm.formGroupList?.[0] && (
                      <FieldGroup
                        legend={data.textFor.myInfoForm.formGroupList[0].label?.value}
                      >
                        {data.textFor.myInfoForm.formGroupList[0].fieldList?.map(
                          (field) => renderUserInformation(field),
                        )}
                      </FieldGroup>
                      )}
                    </FormStructure.FormCell>
                  </FormStructure.FormGroup>
                  <FormStructure.FormGroup>
                    <ControlledBirthdayFields
                      id="myInfo-form"
                      name="birthdayFields"
                      control={control}
                      clearErrors={clearErrors}
                      setError={setError}
                      getValues={getValues}
                    />
                  </FormStructure.FormGroup>
                  <FormStructure.FormGroup>
                    <FormStructure.FormCell isLoading={queryLoading}>
                      {data.textFor.myInfoForm.formGroupList?.[1] && (
                      <FieldGroup
                        legend={data.textFor.myInfoForm.formGroupList[1].label?.value}
                      >
                        {data.textFor.myInfoForm.formGroupList[1].fieldList?.map(
                          (field) => renderUserInformation(field),
                        )}
                      </FieldGroup>
                      )}
                      <LegalText>
                        <TmntText tmnt={data.textFor.passwordCharacterLengthText} />
                      </LegalText>
                    </FormStructure.FormCell>
                  </FormStructure.FormGroup>
                  <FormStructure.FormGroup>
                    <FormStructure.FormCell isLoading={queryLoading}>
                      {data.textFor.myInfoForm.formGroupList?.[2] && (
                      <FieldGroup
                        legend={data.textFor.myInfoForm.formGroupList[2].label?.value}
                      >
                        {data.textFor.myInfoForm.formGroupList[2].fieldList?.map(
                          (field) => renderUserInformation(field),
                        )}
                      </FieldGroup>
                      )}
                    </FormStructure.FormCell>
                  </FormStructure.FormGroup>
                  <FormStructure.FormGroup>
                    <FormStructure.FormCell>
                      <hr />
                    </FormStructure.FormCell>
                  </FormStructure.FormGroup>
                  <FormStructure.FormGroup>
                    {data.textFor.myInfoLegalText && (
                      data.legalModalEnabled ? (
                        <FormStructure.FormCell isLoading={queryLoading}>
                          <div data-testid="legal-text">
                            <LegalModalButton
                              text={data.textFor.myInfoLegalText.text}
                              ref={legalLinkRef}
                            />
                            {legalTmntKey && (
                            <ModalContextProvider>
                              <LegalModal
                                legalText={legalTmntKey}
                                onClose={() => setLegalTmntKey(null)}
                              />
                            </ModalContextProvider>
                            )}
                          </div>
                        </FormStructure.FormCell>
                      ) : (
                        <FormStructure.FormCell>
                          <LegalText>
                            <TmntHtml tmnt={data.textFor.myInfoLegalText.text} />
                          </LegalText>
                        </FormStructure.FormCell>
                      )
                    )}
                  </FormStructure.FormGroup>
                  <FormStructure.FormGroup>
                    { isDirty && !isValid && (
                    <FormStructure.FormCell>
                      <ErrorMessage id="my-info-form-error-message">{data.textFor.myInfoForm.error?.value}</ErrorMessage>
                    </FormStructure.FormCell>
                    )}
                    { isDirty && errors.age && (
                    <FormStructure.FormCell>
                      <ErrorMessage id="myinfo-age-error-message">{data.textFor.buttonInvalidBirthdayErrorText.value}</ErrorMessage>
                    </FormStructure.FormCell>
                    )}

                    <FormStructure.FormCell>
                      {renderButton({
                        initial: {
                          children: <TmntText tmnt={data.textFor.buttonUpdateText} />,
                          isFullWidth: true,
                          type: 'submit',
                          variant: 'secondary',
                          isDisabled: !isDirty,
                        },
                        processing: {
                          children: <TmntText tmnt={data.textFor.buttonProcessingText} />,
                          isFullWidth: true,
                          variant: 'secondary',
                        },
                        error: {
                          children: <TmntText tmnt={data.textFor.buttonErrorText} />,
                          isFullWidth: true,
                          variant: 'secondary',
                        },
                        success: {
                          children: (
                            <>
                              <Icon icon="check" />
                              <TmntText tmnt={data.textFor.buttonUpdatedText} />
                            </>
                          ),
                          isFullWidth: true,
                          variant: 'secondary',
                        },
                      })}
                    </FormStructure.FormCell>
                  </FormStructure.FormGroup>
                </FormStructure.FormWrapper>
              </form>
            </Column>
          </Row>
        </AccountBlock>
      ) : null}
    </div>
  );
}
