import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import MobileSignInButton from './MobileSignInButton';
import SignInMenu from './SignInMenu';
import SignInToaster from './SignInToaster';
import { MOBILE_SIGN_IN_QUERY } from './operations';
import { SignInToasterContext } from '../../context/ModalContext';
import AuthenticationModal from './AuthenticationModal';

const MOBILE_SIGN_IN = 'mobile-sign-in';

const renderContainer = (children) => (
  <div className={MOBILE_SIGN_IN} data-testid={MOBILE_SIGN_IN}>
    { children }
  </div>
);

export default function MobileSignIn() {
  const { openModal: openToaster } = useContext(SignInToasterContext);

  const { data = {} } = useQuery(
    MOBILE_SIGN_IN_QUERY,
    {
      ssr: false,
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { userState = {}, config = {} } = data;
  const { isLoggedIn = false } = userState;
  const { isLoyaltyEnabled } = config;

  return renderContainer((
    <>
      <MobileSignInButton onClick={openToaster} isLoggedIn={isLoggedIn} />
      <>
        {/* Auth Modal */}
        <AuthenticationModal isLoyaltyEnabled={isLoyaltyEnabled} />

        {/* Sign In Toaster */}
        <SignInToaster>
          <SignInMenu data-testId="mobile-account-menu" isLoggedIn={isLoggedIn} />
        </SignInToaster>
      </>
    </>
  ));
}
