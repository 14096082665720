import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ApplePayButton from '../ApplePayButton/ApplePayButton';
import ApplePay from '../../CheckoutPage/PaymentManager/ApplePay/ApplePay';
import ApplePayExpress from '../ApplePayButton/ApplePayExpress';
import PaypaylExpressButton from '../PaypalExpressButton/PaypalExpressButton';
import SwitchTestContext from '../../../context/SwitchTestContext';
import $window from '../../../tools/window';

const PAYPAL_IMAGE_URL = 'https://img.abercrombie.com/is/image/anf/hol-M-20180411-lp-omni-paypal.png';
export default function ExpressCheckoutWrapper({
  updatePageState,
  isDisabled,
  page,
  pageContext,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);

  const hasApplePayPXP = digitalData && digitalData['chk-has-apple-pay-pxp'];
  const hasApplePayLD = digitalData && digitalData['chk-has-apple-pay'];
  const hasApplePay = pageContext?.applePayButton?.hasApplePay
  && !!$window.ApplePaySession
  && hasApplePayLD;

  return (
    <>
      {hasApplePay && (
        <>
          <ApplePayButton
            orderConfirmationURL={pageContext?.applePayButton?.orderConfirmationURL}
            tmntData={pageContext?.textFor}
            isDisabled={isDisabled}
          />
          {hasApplePayPXP && (
            <>
              <ApplePay
                applePayConfig={pageContext?.paymentConfig?.applePay ?? {}}
                orderTotals={pageContext?.orderTotals ?? {}}
                isExpressFlow
                updatePageState={updatePageState}
                hasApplePayPXP={hasApplePayPXP}
                countriesConfig={pageContext?.orderSubmitLegalTerms?.countriesConfig ?? []}
              />

              {page !== 'checkout' ? (
                <ApplePayExpress
                  page={page}
                  orderConfirmationURL={pageContext?.applePayButton?.orderConfirmationURL}
                />
              )
                : null}
            </>
          )}
        </>
      )}
      {pageContext?.payPalExpressButton?.hasPayPal && (
        <PaypaylExpressButton
          paypalExpressURL={pageContext?.payPalExpressButton?.paypalExpressUrl}
          paypalExpressImage={PAYPAL_IMAGE_URL}
          payPalMerchantId={
            pageContext?.payPalExpressButton.payPalEnvironment
          }
          payPalEnvironment={
            pageContext?.payPalExpressButton.payPalMerchantId
          }
          PayPalInContextScriptSrc={
            pageContext?.payPalExpressButton.PayPalInContextScriptSrc
          }
          tmntData={pageContext?.textFor}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}

ExpressCheckoutWrapper.propTypes = {
  updatePageState: PropTypes.func,
  isDisabled: PropTypes.bool,
  page: PropTypes.string.isRequired,
  pageContext: PropTypes.instanceOf(Object).isRequired,
};

ExpressCheckoutWrapper.defaultProps = {
  updatePageState: () => {},
  isDisabled: false,
};
