import { useQuery } from '@apollo/client';
import useDigitalData from '../../../hooks/useDigitalData';
import {
  DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
} from '../../DigitalDataProvider';
import { STORE_ATTRIBUTES } from '../queries';

const useFeatureFlags = () => {
  const { data: storeAttributeData } = useQuery(STORE_ATTRIBUTES);

  const {
    [DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS]: departmentSuggestionsFlag = false,
  } = useDigitalData([
    DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
  ]);

  const hasShopMyStoreEnabled = storeAttributeData?.config?.hasShopMyStoreEnabled?.value ?? false;

  return {
    departmentSuggestionsFlag,
    hasShopMyStoreEnabled,
  };
};

export default useFeatureFlags;
